import React from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'

export const NotificationErrorDateUpdate = () => (
  <BannerNotification
    variant="error"
    id="error-date-update"
    title="Something has gone wrong"
    isDismissable
  >
    Your delivery date could not be updated at this time. Please try again
  </BannerNotification>
)
