import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'

export const NotificationSuccessSubscriptionRestarted = () => (
  <BannerNotification
    variant="success"
    title="Welcome back!"
    id="success-subscription-restarted"
    isDismissable
    shouldAutoDismiss
  >
    Your subscription has been activated. Please check that your subscription
    preferences and delivery address are up to date.
  </BannerNotification>
)
