import {
  addNotification,
  removeNotification,
} from '@mindfulchefuk/features/Notifications/notificationsSlice'
import { RootState } from '@mindfulchefuk/types/store'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useNotifyIfOffline = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(
    (state: RootState) => state.notifications.list
  )

  const hasNotification = notifications.includes('warning-offline')
  const { isOffline } = useNavigatorOnline()

  useEffect(() => {
    if (isOffline && !hasNotification) {
      dispatch(addNotification({ id: 'warning-offline' }))
    }

    if (!isOffline && hasNotification) {
      dispatch(removeNotification('warning-offline'))
    }
  }, [dispatch, hasNotification, isOffline])
}
