import React from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'

export const NotificationErrorDateReset = () => (
  <BannerNotification
    variant="error"
    id="error-date-reset"
    title="Delivery date reset"
    isDismissable
  >
    The delivery date you had selected is unavailable. It has been reset to the
    earliest available date
  </BannerNotification>
)
