import React, { FunctionComponent } from 'react'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { Flex, Box } from '@mindfulchefuk/design-system/layout'
import NextLink from 'next/link'
import { ResetButton } from '@mindfulchefuk/design-system/Button'
import { Text } from '@mindfulchefuk/design-system/Text'
import { IconType } from '@mindfulchefuk/design-system/Icon/svgs'
import styled from '@emotion/styled'
import { useNavClickHandler } from '@mindfulchefuk/features/Navigation/hooks'

interface Props {
  onClick?: (labelId: string) => void
  active?: boolean
  id: string
  icon?: IconType
  hasSubMenu?: boolean
  href?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const StyledNavItem = styled(Box)`
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export const NavItem: FunctionComponent<Props> = ({
  children,
  onClick,
  active = false,
  id,
  icon,
  hasSubMenu,
  href,
  onMouseLeave,
  onMouseEnter,
}) => {
  const handleClick = useNavClickHandler({
    action: 'nav-dropdown-clicked',
    label: id,
    onClick,
  })

  const navItem = (
    <StyledNavItem
      as="li"
      listStyleType="none"
      display="flex"
      color="inherit"
      onFocus={onMouseEnter}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      position="relative"
    >
      <ResetButton onClick={handleClick} fullWidth>
        <Flex as="span" align="center">
          {icon && <Icon type={icon} mr={10} />}
          <Text
            as="span"
            variant="body-sm-500-caps"
            py={{ base: 12, lg: 24 }}
            color="inherit"
          >
            {children}
          </Text>
          {hasSubMenu && (
            <Icon
              ml={8}
              color="inherit"
              type={active ? 'chevron-up' : 'chevron-down'}
              size={20}
              minWidth={20}
            />
          )}
        </Flex>
      </ResetButton>
    </StyledNavItem>
  )

  if (href)
    return (
      <NextLink href={href} passHref>
        {navItem}
      </NextLink>
    )

  return navItem
}
