import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'

export const NotificationSuccessAccountDeactivated = () => (
  <BannerNotification
    variant="success"
    title="Your subscription is now cancelled"
    id="success-account-deactivated"
    isDismissable
    shouldAutoDismiss
  >
    Thanks for your feedback!
  </BannerNotification>
)
