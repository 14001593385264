import React, { FunctionComponent } from 'react'
import NextLink from 'next/link'
import { Button, ButtonProps } from '@mindfulchefuk/design-system/Button'
import { useNavClickHandler } from '@mindfulchefuk/features/Navigation/hooks'

type NavCTA = 'primary' | 'secondary' | 'secondary-white' | 'bbc-good-food'

type Props = {
  variant: NavCTA
  testId: string
  onClick?: (labelId: string) => void
  href?: string
  size?: ButtonProps['size']
  fullWidth?: boolean
}

export const NavCTA: FunctionComponent<Props> = ({
  variant,
  testId,
  onClick,
  children,
  href,
  size,
  fullWidth = false,
}) => {
  const handleClick = useNavClickHandler({
    action: 'nav-cta-clicked',
    label: testId,
    onClick,
  })

  return (
    <NextLink href={href} passHref>
      <Button
        as="a"
        data-testid={testId}
        onClick={handleClick}
        variant={variant}
        size={size ?? { base: 'small', lg: 'medium' }}
        fullWidth={fullWidth}
      >
        {children}
      </Button>
    </NextLink>
  )
}
