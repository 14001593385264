import {
  analyticsEvents,
  PageSource,
  PauseForPeriod,
} from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentPauseSubscription = (numberOfDeliveries: number) => {
  const { name, data } = analyticsEvents.pauseSubscription(numberOfDeliveries)

  instrument(name, { ...data })
}

export const instrumentPauseSubscriptionCancel = () => {
  const { name, data } = analyticsEvents.pauseSubscriptionCancel()

  instrument(name, { ...data })
}

export const instrumentPauseSubscriptionConfirm = ({
  id,
  skippedDeliveries,
}: {
  id: string
  skippedDeliveries: number
}) => {
  const { name, data } = analyticsEvents.pauseSubscriptionConfirm({
    id,
    skippedDeliveries,
  })

  instrument(name, { ...data })
}

export const instrumentPauseSubscriptionDateChose = ({
  id,
  date,
}: {
  id: string
  date: string
}) => {
  const { name, data } = analyticsEvents.pauseSubscriptionDateChosen({
    id,
    date,
  })

  instrument(name, { ...data })
}

export const instrumentPauseSubscriptionLonger = () => {
  const { name, data } = analyticsEvents.pauseSubscriptionLonger()

  instrument(name, { ...data })
}

export const instrumentResumeSubscription = ({
  pageSource,
}: {
  pageSource: PageSource
}) => {
  const { name, data } = analyticsEvents.resumeSubscription({ pageSource })

  instrument(name, { ...data })
}

export const instrumentResumeSubscriptionCancel = () => {
  const { name, data } = analyticsEvents.resumeSubscriptionCancel()

  instrument(name, { ...data })
}

export const instrumentResumeSubscriptionConfirm = () => {
  const { name, data } = analyticsEvents.resumeSubscriptionConfirm()

  instrument(name, { ...data })
}

export const instrumentPauseForPeriodClicked = (
  periodPaused: PauseForPeriod,
  numberOfDeliveries?: number
) => {
  const { name, data } = analyticsEvents.pauseForPeriodClicked(
    periodPaused,
    numberOfDeliveries
  )

  instrument(name, { ...data })
}

export const instrumentPauseForPeriodConfirmed = (
  periodPaused: PauseForPeriod,
  skippedDeliveries?: number
) => {
  const { name, data } = analyticsEvents.pauseForPeriodConfirmed(
    periodPaused,
    skippedDeliveries
  )
  instrument(name, { ...data })
}

export const instrumentPauseForPeriodCancelled = (
  periodPaused: PauseForPeriod
) => {
  const { name, data } = analyticsEvents.pauseForPeriodCancelled(periodPaused)

  instrument(name, { ...data })
}
