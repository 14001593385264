import React, { ReactElement } from 'react'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { ButtonProps } from '@mindfulchefuk/design-system/Button'
import { NavCTA } from '@mindfulchefuk/features/Navigation/components/Header/shared/NavCTA'

type TNavSignInCTA = {
  size?: ButtonProps['size']
  fullWidth?: boolean
  onClick?: () => void
  variant?: 'secondary-white' | 'secondary'
}

export const NavSignInCTA = ({
  size,
  fullWidth = false,
  onClick,
  variant = 'secondary',
}: TNavSignInCTA): ReactElement => (
  <NavCTA
    variant={variant}
    testId="nav-cta-sign-in"
    size={size}
    fullWidth={fullWidth}
    href={routesConfig.signIn.pathname}
    onClick={onClick}
  >
    Sign in
  </NavCTA>
)
