import React from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'

export const NotificationErrorPasswordFailedToUpdate = () => (
  <BannerNotification
    variant="error"
    id="error-password-failed-to-update"
    title="Password failed to update"
    isDismissable
  >
    Please try again
  </BannerNotification>
)
