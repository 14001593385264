import React, { FunctionComponent } from 'react'
import {
  ContentWrapper,
  RoundelContainer,
} from '@mindfulchefuk/design-system/Roundel/Roundel.styles'
import { RoundelProps } from '@mindfulchefuk/design-system/Roundel/Roundel.types'
import { colors } from '@mindfulchefuk/design-system/system/theme'

export const Roundel: FunctionComponent<RoundelProps> = ({
  filled,
  size,
  fontSize,
  disabled,
  color = colors.aubergine,
  transparent,
  disableOpacity = false,
  children,
  margin = '0 auto',
  ...htmlProps
}) => {
  let backgroundColor = 'none'

  if (!transparent) {
    backgroundColor = filled ? color : colors.white
  }

  return (
    <RoundelContainer {...htmlProps} margin={margin}>
      {children && (
        <ContentWrapper
          filled={filled}
          fontSize={fontSize}
          disabled={disabled}
          disableOpacity={disableOpacity}
          color={color as string}
        >
          {children}
        </ContentWrapper>
      )}
      <svg
        width={size ? `${size}px` : '100%'}
        height={size ? `${size}px` : '100%'}
        viewBox="0 0 60 58"
        version="1.1"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fill: `${backgroundColor}`,
          stroke: `${color}`,
          strokeWidth: '1px',
          opacity: `${disabled && !disableOpacity ? 0.5 : 1}`,
        }}
      >
        <g transform="matrix(2,0,0,2,0,0)">
          <g transform="matrix(1,0,0,1,1.76471,1.75758)">
            <path d="M26.38,13.066C25.937,9.316 24.342,6.125 21.68,3.495C20.158,1.99 18.377,0.923 16.32,0.353C14.987,-0.017 13.634,-0.059 12.267,0.058C10.032,0.25 7.901,0.823 5.944,1.947C2.373,3.998 0.196,6.994 0.013,11.28C-0.001,11.61 -0.004,11.94 0.006,12.268C0.01,12.399 0.016,12.53 0.024,12.662C0.035,12.846 0.051,13.029 0.072,13.212L0.082,13.3C0.099,13.443 0.119,13.585 0.142,13.727C0.156,13.823 0.172,13.917 0.191,14.013L0.194,14.032C0.219,14.162 0.246,14.292 0.276,14.42C0.286,14.503 0.296,14.585 0.308,14.667C0.333,14.835 0.365,15 0.412,15.163C0.432,15.233 0.455,15.303 0.482,15.372L0.522,15.472C0.524,15.483 0.524,15.495 0.525,15.507C0.526,15.534 0.527,15.561 0.53,15.587L0.537,15.624C1.056,17.571 1.925,19.339 3.169,20.91L3.399,21.205C3.459,21.28 3.52,21.355 3.582,21.428C3.762,21.641 3.954,21.842 4.182,22.008C4.189,22.023 4.195,22.039 4.2,22.055L4.216,22.093C4.221,22.101 4.226,22.109 4.233,22.115C5.163,23.021 6.181,23.802 7.361,24.346C7.412,24.38 7.465,24.41 7.518,24.439C7.678,24.523 7.849,24.585 8.022,24.641C8.332,24.793 8.65,24.921 8.98,25.012L9.021,25.024C9.136,25.055 9.253,25.082 9.371,25.104C9.39,25.117 9.411,25.127 9.431,25.135C9.527,25.175 9.635,25.167 9.733,25.198C10.211,25.333 10.699,25.414 11.19,25.452C11.354,25.465 11.518,25.473 11.683,25.477C13.11,25.51 14.53,25.427 15.933,25.142C16.707,24.985 17.483,24.84 18.221,24.542C19.421,24.196 20.546,23.672 21.599,22.998C25.205,20.694 26.894,17.412 26.38,13.065" />
          </g>
        </g>
      </svg>
    </RoundelContainer>
  )
}
