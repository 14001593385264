import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { DateDeliverability } from '@mindfulchefuk/features/Delivery/interfaces'
import { JsonapiError } from 'spraypaint'
import {
  getDateDeliverabilities,
  GetDateDeliverabilitiesParams,
} from '@mindfulchefuk/features/Delivery/services/getDateDeliverabilities'
import toISODateFormat from '@mindfulchefuk/lib/toISODateFormat'
import { useGetCustomer } from '@mindfulchefuk/features/Customer'
import { selectPostcode } from '@mindfulchefuk/features/Customer/selectors'
import { MC_POSTCODE } from '@mindfulchefuk/constants'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'

export const useGetDateDeliverabilities = (
  minDate: Date,
  maxDate: Date,
  { enabled = true, ...options }: UseQueryOptions<DateDeliverability[]> = {}
) => {
  const { data: customerPostcode } = useGetCustomer({ select: selectPostcode })

  const basketPostcode = useSelector(
    (store: RootState) => store.checkout.postcode
  )

  const postcode = customerPostcode || basketPostcode || MC_POSTCODE

  const params: GetDateDeliverabilitiesParams = {
    maxDate: toISODateFormat(maxDate),
    minDate: toISODateFormat(minDate),
    postcode,
  }

  return useQuery<DateDeliverability[], { response: JsonapiError }>({
    queryKey: ['date-deliverabilities', params],
    queryFn: () => getDateDeliverabilities(params),
    ...options,
    initialData: [],
    enabled: enabled && !!minDate && !!maxDate,
  })
}
