import { DeliveriesCustomerStatistic } from '@mindfulchefuk/api-client'

export interface CustomerDeliveryStats {
  billedRecipesCount: number
}

// This returns the total number of meals the customer has ordered
// so a single order of 4 recipes would return 4
export const getCustomerDeliveryStats =
  async (): Promise<CustomerDeliveryStats> => {
    const { data } = await DeliveriesCustomerStatistic.all()

    return {
      billedRecipesCount:
        data[0]?.attributes?.shipped_one_feeds_two_meals_count || 0,
    }
  }
