import React, { FunctionComponent, ReactNode } from 'react'
import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  Notification,
  Stack,
  Text,
} from '@mindfulchefuk/design-system'
import { Spinner } from '@mindfulchefuk/components/Loader'

interface Props {
  onClose?: () => void
  title: string
  isLoading?: boolean
  ctas?: ReactNode
  'data-testid'?: string
  error?: Error
}

export const WarningModal: FunctionComponent<Props> = ({
  title,
  onClose,
  children,
  ctas,
  isLoading,
  error,
  'data-testid': testId,
}) => (
  <Modal size="small" data-testid={testId} closeModal={onClose}>
    {onClose && <ModalHeader closeModal={onClose} />}

    <ModalBody>
      <Box textAlign="center">
        <Icon type="warning" size={36} mb={12} />
        <Text variant="heading-sm" textAlign="center" mb={24}>
          {title}
        </Text>
        <Text variant="body-md" textAlign="center" mb={20}>
          {children}
        </Text>

        {!!error && (
          <Notification
            icon="warning-filled"
            mb={24}
            type="inline"
            variant="error"
            title="Something went wrong"
          >
            {error.message}
          </Notification>
        )}

        {isLoading ? (
          <Box mx="auto">
            <Spinner />
          </Box>
        ) : (
          ctas && (
            <Stack direction="column" spacing={16} mx="auto" justify="center">
              {ctas}
            </Stack>
          )
        )}
      </Box>
    </ModalBody>
  </Modal>
)
