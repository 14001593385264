import React, { useState, VFC, VoidFunctionComponent } from 'react'
import { useSetAtom } from 'jotai'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'
import NextLink from 'next/link'
import { Box, Button } from '@mindfulchefuk/design-system'
import {
  MobileNav,
  NavItem,
  NavLink,
} from '@mindfulchefuk/features/Navigation/components/Header/shared'
import { signedInNav } from '@mindfulchefuk/features/Navigation/data'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'
import {
  instrumentOrderOneOffBoxClicked,
  instrumentPlaceAnOrderClicked,
} from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentDashboard'
import { PageSource, ProductType } from '@mindfulchefuk/analytics'
import { OneFeedsTwoCountBanner } from '@mindfulchefuk/features/Promotions/OneFeedsTwo/OneFeedsTwoCountBanner'
import { useGetCustomerDeliveryStats } from '@mindfulchefuk/features/Delivery/hooks/useGetCustomerDeliveryStats'
import { customerIdAtom } from '@mindfulchefuk/store/customer'

type TSignedInNavMobile = {
  isNewCustomer: boolean
  isOpen: boolean
  firstAvailableDate: string
  onNavClick: (linkId: string) => void
  onNavExpand: (linkId: string) => void
  pageSource: PageSource
  setIsOpen: (open: boolean) => void
}

type NavGroup = 'account' | 'ready-to-go'

export const SignedInNavMobile: VoidFunctionComponent<TSignedInNavMobile> = ({
  isOpen,
  isNewCustomer,
  firstAvailableDate,
  onNavClick,
  onNavExpand,
  pageSource,
  setIsOpen,
}) => {
  const [activeGroup, setActiveGroup] = useState<NavGroup>(null)
  const setCustomerId = useSetAtom(customerIdAtom)
  const { data: customerStats } = useGetCustomerDeliveryStats()

  const handleClose = () => setIsOpen(false)
  const handleGroupClick = (group: NavGroup) => {
    const newGroup = activeGroup === group ? null : group

    setActiveGroup(newGroup)

    if (newGroup) {
      onNavExpand(group)
    }
  }

  const handleCloseEverything = () => {
    setActiveGroup(null)
    handleClose()
  }

  const handleNavLinkClick = (linkId: string) => {
    onNavClick(linkId)
    handleCloseEverything()
  }

  return (
    <MobileNav isOpen={isOpen} onClose={handleClose}>
      <Box color="aubergine" textAlign="left">
        <Box p={20} pb={0}>
          <MindfulChefLogo color="aubergine" height={28} />
        </Box>
        {!isNewCustomer && (
          <Box px={20} py={16} textAlign="left" as="ul" listStyleType="none">
            {signedInNav.orderLinks.map((link) => (
              <NavItem
                key={link.id}
                onClick={handleNavLinkClick}
                id={link.id}
                href={link.href}
                icon={link.icon}
              >
                {link.label}
              </NavItem>
            ))}
          </Box>
        )}

        <Box
          px={20}
          py={16}
          borderTopWidth={isNewCustomer ? 0 : 1}
          color="aubergine"
          borderColor="pumpkin-200"
        >
          {isNewCustomer && (
            <NavSection
              data={signedInNav.onboardingRecipeBoxes}
              activeGroup={activeGroup}
              onGroupClick={handleGroupClick}
              onLinkClick={handleNavLinkClick}
            />
          )}
          {!isNewCustomer && (
            <>
              <NavSection
                activeGroup={activeGroup}
                onGroupClick={handleGroupClick}
                onLinkClick={handleNavLinkClick}
                data={signedInNav.account}
              />
              <NavItem
                id={signedInNav.yourRecipes.id}
                href={signedInNav.yourRecipes.href}
                icon={signedInNav.yourRecipes.icon}
                onClick={handleNavLinkClick}
              >
                {signedInNav.yourRecipes.label}
              </NavItem>
            </>
          )}
          {!isNewCustomer && (
            <NavSection
              activeGroup={activeGroup}
              onLinkClick={(linkId) => {
                instrumentPlaceAnOrderClicked({
                  isUpsell: 'false',
                  pageSource,
                  productCategory: linkId as ProductType,
                  productType: 'ready to go',
                  section: 'top nav',
                })

                handleNavLinkClick(linkId)
              }}
              onGroupClick={handleGroupClick}
              data={signedInNav.readyToGo(firstAvailableDate)}
            />
          )}
          <NavItem
            id={signedInNav.giftCards.id}
            href={signedInNav.giftCards.href}
            icon={signedInNav.giftCards.icon}
            onClick={handleNavLinkClick}
          >
            {signedInNav.giftCards.label}
          </NavItem>

          {isNewCustomer && (
            <NavSection
              activeGroup={activeGroup}
              onGroupClick={handleGroupClick}
              onLinkClick={handleNavLinkClick}
              data={signedInNav.onboardingAccount}
            />
          )}

          {!isNewCustomer && (
            <NextLink href={signedInNav.oneOffOrderCTA.href} passHref>
              <Button
                as="a"
                variant="primary"
                size="small"
                fullWidth
                mt={12}
                onClick={() => {
                  instrumentOrderOneOffBoxClicked()
                  handleNavLinkClick(signedInNav.oneOffOrderCTA.id)
                }}
              >
                {signedInNav.oneOffOrderCTA.label}
              </Button>
            </NextLink>
          )}
          <NextLink href={signedInNav.signOut.href} passHref>
            <Button
              as="a"
              variant="secondary"
              size="small"
              fullWidth
              mt={12}
              onClick={() => {
                window.sessionStorage.removeItem('isLogInDelinquentModalShown')
                handleNavLinkClick(signedInNav.signOut.id)
                setCustomerId('')
              }}
            >
              {signedInNav.signOut.label}
            </Button>
          </NextLink>
          {customerStats && (
            <Box pt={16}>
              <OneFeedsTwoCountBanner
                count={customerStats.billedRecipesCount}
              />
            </Box>
          )}
        </Box>
      </Box>
    </MobileNav>
  )
}

interface NavSectionProps {
  activeGroup: NavGroup
  onGroupClick: (group: string) => void
  onLinkClick: (id: string) => void
  data: NavLinkData
}

const NavSection: VFC<NavSectionProps> = ({
  activeGroup,
  onGroupClick,
  onLinkClick,
  data,
}) => {
  const isActive = activeGroup === data.id

  return (
    <Box key={data.id}>
      <NavItem
        key={data.id}
        hasSubMenu
        icon={data.icon}
        id={data.id}
        active={isActive}
        onClick={() => onGroupClick(data.id)}
      >
        {data.label}
      </NavItem>
      <Box display={isActive ? 'block' : 'none'} listStyleType="none">
        {data.subNav.map((link) => (
          <Box key={link.id} as="li" pl={20}>
            <NavLink
              {...link}
              onClick={() => onLinkClick(link.id)}
              external={link.route === 'external'}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
