import React from 'react'

import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { useGetRedemptions } from '@mindfulchefuk/features/Subscription/hooks/useGetRedemptions'
import { selectWinBack } from '@mindfulchefuk/features/Subscription/selectors/selectWinBack'

const id = 'success-win-back-redemption'

export const NotificationSuccessWinBackRedemption = () => {
  const { data: winBackRedemptions = [] } = useGetRedemptions({
    select: selectWinBack,
  })

  const winBackMessage = winBackRedemptions[0]?.description

  if (winBackRedemptions.length === 0 || !winBackMessage) {
    return null
  }

  return (
    <BannerNotification
      variant="success"
      title="Discounts applied"
      id={id}
      isDismissable
    >
      Your {winBackMessage} offer will be automatically applied at checkout
    </BannerNotification>
  )
}
