import React, { ReactNode } from 'react'

import { ProductType, CategoryName } from '@mindfulchefuk/query'

export interface ProductTypeConfig {
  id: ProductType
  name: CategoryName
  title: string
  shortTitle: string // No more characters than "Gut-boosting-breakfasts"
  image: string
  description?: ReactNode
}

export type ProductTypeConfigs = Record<ProductType, ProductTypeConfig>

const productTypeConfig: ProductTypeConfigs = {
  recipes: {
    id: 'recipes',
    name: 'Recipes',
    shortTitle: 'Recipes',
    title: 'Recipes',
    image: 'navigation/recipe_box.jpg',
  },
  meal: {
    id: 'meal',
    name: 'Ready meals',
    shortTitle: 'Frozen ready meals',
    title: 'Healthy frozen ready meals',
    image: 'navigation/meal.jpg',
    description: (
      <>
        <p>
          We’ve taken our ethically sourced 100% British meat and sustainably
          sourced fish dinners up a notch. Welcome to your new-look healthy
          frozen ready meals. They&apos;re all as balanced as an Olympic gymnast
          and contain no dairy ingredients or gluten. All the feel-good food
          your body needs, straight from the freezer.
        </p>
        <p>
          Now with recyclable packaging, they&apos;re ready-in-a-ping delicious.
        </p>
      </>
    ),
  },
  smoothie: {
    id: 'smoothie',
    name: 'Smoothies',
    shortTitle: 'Nutritious smoothies',
    title: 'Delicious, nutritious smoothies',
    image: 'navigation/smoothie.jpg',
    description: (
      <>
        <p>
          From breakfast with a boost, a health kick in a glass, to the perfect
          sweet treat - we’ve got you covered with our nutritious smoothies
          range.
        </p>
        <p>
          They’re delicious and come frozen to lock in flavour and nutrients.
          Simply blend with your favourite liquid and enjoy.
        </p>
        <p>Plus you won’t find any gluten or dairy ingredients in them.</p>
      </>
    ),
  },
  frozen_desserts: {
    id: 'frozen_desserts',
    name: 'Desserts',
    shortTitle: 'Indulgent desserts',
    title: 'Indulgent desserts',
    image: 'navigation/frozen_desserts.jpg',
    description: (
      <p>
        You’ve had your healthy dinner. Now it’s time for the finale.
        Introducing our new healthy-ish dessert range. We’ve taken everything
        that’s lovely about desserts (chocolate, you know the drill) and turned
        them into four gluten-swerving servings of pure, unadulterated heaven.
        Go on, you deserve it.
      </p>
    ),
  },
  broths_and_soups: {
    id: 'broths_and_soups',
    name: 'Soups',
    shortTitle: 'Soups',
    title: 'Satisfying soups',
    image: 'navigation/broths_and_soups.jpg',
    description: (
      <p>
        Big on flavour, low in fat and perfect for lunch and light suppers. Our
        satisfying soups are packed with protein, seasonal veg, and a healthy
        Mindful Chef twist. That’s it. No preservatives, no nasties - just high
        quality ingredients to help you feel good.
      </p>
    ),
  },
  breakfasts: {
    id: 'breakfasts',
    name: 'Breakfast',
    shortTitle: 'Gut-healthy breakfasts',
    title: 'Gut-healthy breakfasts',
    image: 'navigation/breakfasts.jpg',
    description: (
      <p>
        Healthy gut creds? You&apos;d better believe it. Your gut healthy
        breakfast range is here. And it’s totally tasty, high in fibre, and made
        with 100% natural ingredients your tummy will thank you for. (Psst - no
        added salt, gluten, or dairy ingredients, so they’re vegan friendly,
        too.) So angelic, even the packaging is plastic-free.
      </p>
    ),
  },
  snacks: {
    id: 'snacks',
    name: 'Snacks & Vitamins',
    shortTitle: 'Snacks & Vitamins',
    title: 'Mindful Snacks & Vitamins',
    image: 'navigation/snacks.jpg',
    description: (
      <p>
        Introducing tasty gluten-free, feel-good snacks and vitamins from our
        brand amazing partners.
      </p>
    ),
  },
  bundled_products: {
    id: 'bundled_products',
    name: 'Healthy Bundles',
    shortTitle: 'Healthy bundles',
    title: 'Healthy Bundles',
    image: 'navigation/bundled_products.jpg',
    description: (
      <p>
        Get breakfasts, lunches and dinner faves delivered when you take your
        pick from these great-tasting bundles. Discover Fearne&apos;s favourite
        breakfast smoothies, deeply satisfying lunchtime soups, best-selling
        ready meals and yes, there&apos;s even a (healthy-ish) dessert or two!
      </p>
    ),
  },
}

const { recipes, ...shopProductTypeConfig } = productTypeConfig

const isProductType = (type: string | undefined): type is ProductType =>
  Object.keys(productTypeConfig).includes(type)

const isShopProductType = (type: string | undefined): type is ProductType =>
  Object.keys(shopProductTypeConfig).includes(type)

export {
  isProductType,
  isShopProductType,
  productTypeConfig,
  shopProductTypeConfig,
}
