import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'

export const NotificationSuccessPasswordChanged = () => (
  <BannerNotification
    variant="success"
    title="Your password has been changed successfully"
    id="success-password-changed"
    isDismissable
    shouldAutoDismiss
  >
    Use your new password to sign in
  </BannerNotification>
)
