import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'

export const unauthenticatedNav: NavLinkData[] = [
  {
    id: 'recipe-boxes',
    label: 'Recipe boxes',
    icon: 'box',
    focusLinkId: 'all-recipe-boxes',
    staticLinkId: 'family-recipe-boxes',
    isDynamic: false,
    subNav: [
      {
        id: 'all-recipe-boxes',
        label: 'All recipe boxes',
        href: '/choose-recipes?initialMealPlan=Balanced',
        imageURL: 'header-mega-navigation/all-recipe-boxes.jpg',
        route: 'internal',
      },
      {
        id: 'vegan-recipe-boxes',
        label: 'Vegan recipe boxes',
        href: '/choose-recipes?initialMealPlan=Plant-Based',
        imageURL: 'header-mega-navigation/vegan-recipes-alex.png',
        route: 'internal',
      },
      {
        id: 'family-recipe-boxes',
        label: 'Family recipe boxes',
        href: '/choose-recipes?initialPortionCount=4&initialMealPlan=Balanced',
        imageURL: 'header-mega-navigation/family-recipes.jpg',
        route: 'internal',
      },
    ],
  },
  {
    id: 'how-it-works',
    label: 'How it works',
    icon: 'delivery',
    focusLinkId: 'how-it-works',
    staticLinkId: 'about-us',
    isDynamic: false,
    subNav: [
      {
        id: 'how-it-works',
        label: 'How it works',
        href: '/how-it-works',
        imageURL: 'header-mega-navigation/how-it-works.jpg',
        route: 'internal',
      },
      {
        id: 'about-us',
        label: 'About us',
        href: '/about-us',
        imageURL: 'header-mega-navigation/about-us.jpg',
        route: 'external',
      },
      {
        id: 'suppliers',
        label: 'Our suppliers',
        href: '/suppliers',
        imageURL: 'header-mega-navigation/suppliers-alex.jpg',
        route: 'external',
      },
    ],
  },
  {
    id: 'healthy-recipes',
    label: 'Search Recipes',
    icon: 'chef',
    href: '/healthy-recipes',
    route: 'internal',
  },
  {
    id: 'gift-cards',
    label: 'Gift cards',
    icon: 'gift-box',
    href: '/gift-cards',
    route: 'internal',
  },
]
