import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'

export const NotificationSuccessFrequencyUpdated = () => (
  <BannerNotification
    variant="success"
    title="Frequency Updated"
    id="success-frequency-updated"
    isDismissable
    shouldAutoDismiss
  >
    You can view and edit your recipes and deliveries from the calendar.
  </BannerNotification>
)
