import { maxWidths, breakpointsArray } from '@mindfulchefuk/design-system'

import {
  CMSResponsiveImageRaw,
  CMSBannerRaw,
  CMSBanner,
  CMSBannerImage,
} from '@mindfulchefuk/features/CMS/interfaces'

export function transformBanners(banners: CMSBannerRaw[]): CMSBanner[] {
  return banners.map((banner: CMSBannerRaw) => {
    return {
      ...banner,
      analyticsGaClickLabelValue: `${banner.title} Banner Clicked`,
      image: transformCMSImage(banner.image, banner.title),
    }
  })
}

export function transformCMSImage(
  responsiveImage: CMSResponsiveImageRaw | null,
  imageDefaultAltTagPrefix: string
): CMSBannerImage {
  if (!responsiveImage) {
    return null
  }
  const altText = responsiveImage.alternativeText
  const sources = [
    responsiveImage.mobile,
    responsiveImage.tablet,
    responsiveImage.desktop,
  ]
    .filter((image) => !!image)
    .reduce((responsiveImageArray, image, index) => {
      const nextBreakpoint = breakpointsArray[index + 1]
      responsiveImageArray.push({
        media: maxWidths[nextBreakpoint],
        src: `${image.hash}${image.ext}`,
        width: image.width,
      })

      return responsiveImageArray
    }, [])

  return {
    src: sources.length ? sources[sources.length - 1].src : null,
    altText: altText || `${imageDefaultAltTagPrefix} banner image`,
    sources,
  }
}
