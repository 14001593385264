import React, { FC, VoidFunctionComponent } from 'react'

import { Text, Flex, Box, Icon, IconProps } from '@mindfulchefuk/design-system'
import { IconType } from '@mindfulchefuk/design-system/Icon/svgs'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { getFoodGroupsLabel } from '@mindfulchefuk/features/Recipes/components/RecipeFilter/RecipeFilterFoodGroups'
import { prettifyWeekdays } from '@mindfulchefuk/features/Subscription/utils/prettifyWeekdays'

export const SubscriptionIcons: VoidFunctionComponent<Props> = ({
  includeDayOfTheWeek,
  subscription,
}) => {
  const {
    excludedFoodGroups,
    portionCountPerMeal,
    deliveryFrequencyWeeks,
    deliveryWeekdays,
  } = subscription

  const foodGroupLabel = getFoodGroupsLabel(excludedFoodGroups)

  let icon: IconType = 'knife-and-fork'
  if (foodGroupLabel === 'Vegan') icon = 'vegan'
  if (foodGroupLabel === 'Pescetarian') icon = 'fish'

  const days = prettifyWeekdays(deliveryWeekdays)

  return (
    <>
      <TextWithIcon icon={icon}>{foodGroupLabel}</TextWithIcon>
      <PortionTextWithIcon portionCountPerMeal={portionCountPerMeal} />
      <FrequencyTextWithIcon deliveryFrequencyWeeks={deliveryFrequencyWeeks} />
      {includeDayOfTheWeek && (
        <TextWithIcon icon="calendar">{days}</TextWithIcon>
      )}
    </>
  )
}

type PortionTextWithIconProps = {
  portionCountPerMeal: number
}

const PortionTextWithIcon = ({
  portionCountPerMeal,
}: PortionTextWithIconProps) => {
  switch (portionCountPerMeal) {
    case 1:
      return (
        <TextWithIcon strokeWidth="1.1" icon="person">
          for 1
        </TextWithIcon>
      )
    case 2:
      return (
        <TextWithIcon strokeWidth="1.1" icon="people">
          for 2
        </TextWithIcon>
      )
    case 4:
      return (
        <TextWithIcon strokeWidth="1.1" icon="people">
          for 4
        </TextWithIcon>
      )
    default:
      return null
  }
}

type FrequencyTextWithIconProps = {
  deliveryFrequencyWeeks: number
}

const FrequencyTextWithIcon = ({
  deliveryFrequencyWeeks,
}: FrequencyTextWithIconProps) => {
  const week = ['Weekly', 'Fortnightly', 'Every 3 weeks', 'Every 4 weeks'][
    deliveryFrequencyWeeks - 1
  ]

  if (!week) return null

  return (
    <TextWithIcon strokeWidth="0.7" icon="delivery">
      {week}
    </TextWithIcon>
  )
}

interface Props {
  includeDayOfTheWeek?: boolean
  subscription: Subscription
}

const TextWithIcon: FC<{
  icon: IconType
  strokeWidth?: IconProps['strokeWidth']
}> = ({ children, icon, strokeWidth }) => (
  <Flex align="center" mr={16} mb={4}>
    <Box size={20} minWidth={20} mr={10}>
      <Icon type={icon} strokeWidth={strokeWidth} />
    </Box>
    <Text variant="body-sm-500-caps">{children}</Text>
  </Flex>
)
