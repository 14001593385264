import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { MouseEventHandler, useCallback } from 'react'

interface Props {
  action: string
  label: string
  onClick?: (label: string) => void
}
export const useNavClickHandler = ({
  action,
  label,
  onClick,
}: Props): MouseEventHandler => {
  const handleClick = useCallback(() => {
    if (onClick) onClick(label)
    return logEvent({
      category: ANALYTICS_CATEGORIES.navigation,
      action,
      label,
    })
  }, [action, label, onClick])

  return handleClick
}
