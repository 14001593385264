import {
  DELIVERY_EDIT_GRACE_PERIOD,
  ORDER_RANGE,
} from '@mindfulchefuk/constants'
import toISODateFormat from '@mindfulchefuk/lib/toISODateFormat'
import { addDays } from 'date-fns'

export function getImportantDates() {
  const recipeBoxBuildTime = DELIVERY_EDIT_GRACE_PERIOD
  const orderLimit = ORDER_RANGE
  const now = new Date()
  const tomorrow = addDays(now, 1)

  const firstBuildDateFromToday = addDays(now, recipeBoxBuildTime)
  const maxPastDate = addDays(now, orderLimit)

  return {
    firstBuildDate: toISODateFormat(firstBuildDateFromToday),
    tomorrow: toISODateFormat(tomorrow),
    maxPastDate: toISODateFormat(maxPastDate),
  }
}
