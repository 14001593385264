import get from 'lodash/get'

export interface LoqateFeatures {
  phone: string
  email: string
  address: string
}

export const getFeature = <T, F extends string = string>(
  feature: F
): F extends 'loqate' ? LoqateFeatures : T | undefined => {
  if (typeof window === 'undefined') return undefined
  return get(window, `mc.features.${feature}`)
}
