import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getSubscription } from '@mindfulchefuk/features/Subscription/services/getSubscription'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { STALE_15_MINS } from '@mindfulchefuk/constants'

export const useGetSubscription = (
  options: UseQueryOptions<Subscription> = {}
) => {
  const customerId = getCustomerId()

  return useQuery({
    queryKey: ['subscription', { customerId }],
    queryFn: () => getSubscription(customerId),
    enabled: !!customerId,
    staleTime: STALE_15_MINS,
    ...options,
  })
}
