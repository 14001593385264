import router from 'next/router'
import React from 'react'

import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'

export const NotificationSuccessDeliveryDateChanged = () => {
  const { deliveryDate } = router.query

  return (
    <BannerNotification
      variant="success"
      title="Delivery date updated"
      id="success-delivery-date-changed"
      isDismissable
      shouldAutoDismiss
    >
      Your box will now be delivered on{' '}
      {dateFormats.long(deliveryDate.toString())}
    </BannerNotification>
  )
}
