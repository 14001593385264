import { analyticsEvents, PageSource } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

type NavProps = {
  link: string
  section: 'top nav' | 'footer'
  pageSource: PageSource
}

export const instrumentNavExpanded = ({
  link,
  section,
  pageSource,
}: NavProps) => {
  const { name, data } = analyticsEvents.navExpanded({
    link,
    section,
    pageSource,
  })

  instrument(name, { ...data })
}

export const instrumentNavClicked = ({
  link,
  section,
  pageSource,
}: NavProps) => {
  const { name, data } = analyticsEvents.navClicked({
    link,
    section,
    pageSource,
  })

  instrument(name, { ...data })
}
