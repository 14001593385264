import { format } from 'date-fns'

// https://medium.com/@KevinBGreene/surviving-the-typescript-ecosystem-branding-and-type-tagging-6cf6e516523d
type Brand<K, T> = K & { __brand: T }

// e.g. 2020-01-01
export type ISODate = Brand<string, 'ISODate'>

// e.g. January 1st
export type SummaryDate = Brand<string, 'SummaryDate'>

// e.g. Sat 1st Jan
export type ShortDate = Brand<string, 'ShortDate'>

// e.g. Saturday 1st January
export type LongDate = Brand<string, 'LongDate'>

export const dateFormats = {
  iso: (date: Date | string) => format(date, 'YYYY-MM-DD') as ISODate,
  summary: (date: Date | string) => format(date, 'MMMM Do') as SummaryDate,
  short: (date: Date | string) => format(date, 'ddd Do MMM') as ShortDate,
  long: (date: Date | string) => format(date, 'dddd Do MMMM') as LongDate,
}
