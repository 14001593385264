import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import {
  ResumeSubscriptionModal,
  RestartSubscriptionSuccessModal,
  useUpdateSubscription,
} from '@mindfulchefuk/features/Subscription'
import { removeNotification } from '@mindfulchefuk/features/Notifications/notificationsSlice'

import { Button, Notification, Flex, Text } from '@mindfulchefuk/design-system'
import { GENERIC_ERROR_MESSAGE } from '@mindfulchefuk/constants'
import { useGetSubscription } from '@mindfulchefuk/features/Subscription/hooks/useGetSubscription'

import { format } from 'date-fns'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import {
  instrumentResumeSubscription,
  instrumentResumeSubscriptionConfirm,
} from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentPauseResumeSubscriptionEvents'
import { triggerHotJarEvent } from '@mindfulchefuk/utils/analytics/hotJar/triggerHotJarEvent'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'

export const SubscriptionPaused = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const pageSource = usePageSource()

  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const { data: subscription } = useGetSubscription()

  const pausedUntilDeliveryDate = subscription?.pausedUntil
    ? format(subscription?.pausedUntil, 'dddd Do MMMM')
    : null

  const [cachedPausedUntilDate] = useState(pausedUntilDeliveryDate)

  const pausedUntilDate = cachedPausedUntilDate || pausedUntilDeliveryDate

  const { mutate: updateSubscription, error: updateSubscriptionError } =
    useUpdateSubscription({
      onSuccess: () => {
        triggerHotJarEvent('resume subscription confirmed')
        instrumentResumeSubscriptionConfirm()
        setShowModal(false)
        setShowSuccessModal(true)
      },
      onError: () => {
        setShowModal(false)
      },
    })

  const handleResumePausedSubscription = () => {
    updateSubscription({ pausedUntil: null })
  }

  const navigateToCalendar = () => {
    router.push(routesConfig.upcoming.pathname)
  }

  const handleCloseResumeModal = () => {
    dispatch(removeNotification('warning-subscription-paused'))
    setShowSuccessModal(false)
  }

  return (
    <>
      {updateSubscriptionError && (
        <Notification
          type="inline"
          variant="error"
          title={GENERIC_ERROR_MESSAGE}
        >
          {updateSubscriptionError.message}
        </Notification>
      )}
      <BannerNotification
        title="Subscription paused"
        variant="warning"
        id="warning-subscription-paused"
        textAlign="center"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
        >
          <Text variant="body-md" maxWidth={['100%', '80%']} mr={['0', '24']}>
            {`Your recipe subscription is paused until ${pausedUntilDate}. You
            can still place and receive one-off orders in the meantime.`}
          </Text>

          <Button
            variant="primary"
            ml={[8, 0]}
            mt={[12, 0]}
            size="small"
            onClick={() => {
              instrumentResumeSubscription({
                pageSource,
              })
              setShowModal(true)
            }}
          >
            Resume subscription now
          </Button>
        </Flex>
      </BannerNotification>
      {showModal && (
        <ResumeSubscriptionModal
          onResumeClick={handleResumePausedSubscription}
          onClose={() => setShowModal(false)}
          subscription={subscription}
        />
      )}
      {showSuccessModal && (
        <RestartSubscriptionSuccessModal
          source="preferences"
          type="resumed"
          onOrdersClick={navigateToCalendar}
          onPreferencesClick={handleCloseResumeModal}
          onClose={handleCloseResumeModal}
          subscription={subscription}
        />
      )}
    </>
  )
}
