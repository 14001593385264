import { SubscriptionsSubscription } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { transformSubscription } from '@mindfulchefuk/features/Subscription/transforms/transformSubscription'

export type UpdateSubscriptionParams = Partial<
  Pick<
    Subscription,
    | 'defaultDeliveryOptionId'
    | 'deliveryFrequencyWeeks'
    | 'deliveryWeekdays'
    | 'excludedFoodGroups'
    | 'mealCountPerDelivery'
    | 'mealPlan'
    | 'portionCountPerMeal'
    | 'pausedUntil'
    | 'cancelledAt'
  >
>

export const updateSubscription = async (
  {
    defaultDeliveryOptionId,
    deliveryFrequencyWeeks,
    deliveryWeekdays,
    excludedFoodGroups,
    mealCountPerDelivery,
    mealPlan,
    portionCountPerMeal,
    pausedUntil,
    cancelledAt,
  }: UpdateSubscriptionParams,
  customerId: string
): Promise<Subscription> => {
  try {
    const subscription = new SubscriptionsSubscription({ id: customerId })

    subscription.isPersisted = true
    subscription.delivery_frequency_weeks = deliveryFrequencyWeeks
    subscription.delivery_weekdays = deliveryWeekdays
    subscription.excluded_food_groups = excludedFoodGroups
    subscription.meal_count_per_delivery = mealCountPerDelivery
    subscription.meal_plan = mealPlan
    subscription.portion_count_per_meal = portionCountPerMeal
    subscription.paused_until = pausedUntil
    subscription.cancelled_at = cancelledAt
    subscription.default_delivery_option_id = defaultDeliveryOptionId

    // persist the changes
    const success = await subscription.save()
    if (!success) {
      throw new Error(
        'Unfortunately we didn’t manage to update your subscription at this time. Please try again later.'
      )
    }

    return transformSubscription(subscription)
  } catch (e) {
    errorHandler(e)
  }
}
