import React, { ReactElement } from 'react'
import NextLink from 'next/link'

import { BoxProps, Flex } from '@mindfulchefuk/design-system'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { instrumentNavClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentNavigation'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'
import { isBBCGoodFoodSession } from '@mindfulchefuk/features/Homepage/utils/isBBCGoodFoodSession'
import { BBCGoodFoodLogo } from '@mindfulchefuk/features/Homepage/components/BBCGoodFood/BBCGoodFoodLogo'

export const LogoLinkWrapper: React.VFC<{
  disableLink: boolean
  display: BoxProps['display']
  logo: ReactElement
  isOnboarding: boolean
}> = ({ logo, display, disableLink, isOnboarding }) => {
  const { isCustomer, isNewCustomer } = useIsNewCustomer()
  const pageSource = usePageSource()
  const isBBCGFSessionOnboarding = isOnboarding && isBBCGoodFoodSession()

  const bbcGoodFoodLogo = (
    <BBCGoodFoodLogo py={12} color="bbc-good-food-black" width="140px" />
  )

  const logoToRender = isBBCGFSessionOnboarding ? bbcGoodFoodLogo : logo

  if (!logoToRender) return null

  if (disableLink) {
    return (
      <Flex
        onClick={() => {
          // currently we are only track the signed in navigation
          if (isCustomer) {
            instrumentNavClicked({
              link: 'mc-logo',
              section: 'top nav',
              pageSource,
            })
          }
        }}
        as="a"
        display={display}
      >
        {logoToRender}
      </Flex>
    )
  }

  const logoUrl =
    isCustomer && !isNewCustomer
      ? routesConfig.upcoming.pathname
      : routesConfig.home.pathname

  return (
    <NextLink
      href={isBBCGFSessionOnboarding ? '/bbc-good-food' : logoUrl}
      passHref
    >
      <Flex
        onClick={() => {
          // currently we are only track the signed in navigation
          if (isCustomer) {
            instrumentNavClicked({
              link: 'mc-logo',
              section: 'top nav',
              pageSource,
            })
          }
        }}
        as="a"
        display={display}
      >
        {logoToRender}
      </Flex>
    </NextLink>
  )
}
