import React from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'

export const NotificationWarningOffline = () => (
  <BannerNotification
    id="warning-offline"
    variant="warning"
    title="You are offline"
  >
    Retrying...
  </BannerNotification>
)
