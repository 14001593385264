import { ExternalPaymentsPaymentMethod } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

export type CardDetails = {
  expiryDate: string
  lastFourDigits?: string
  cardType: string
}

export const getPaymentMethod = async (
  customerId: string
): Promise<CardDetails | undefined> => {
  try {
    const result = await ExternalPaymentsPaymentMethod.where({
      customer_id: customerId,
      primary: true,
    })
      .extraParams({
        pagination_links: 'false',
      })
      .find()

    const { data } = result
    if (!data) return null

    return {
      expiryDate: `${data.expiry_month}/${data.expiry_year}`,
      lastFourDigits: data.last_4,
      cardType: data.scheme,
    }
  } catch (err) {
    errorHandler(err)

    throw err
  }
}
