import { DependencyList, useEffect } from 'react'

export default function useScrollToTop(
  conditional: DependencyList = [],
  behavior: 'auto' | 'smooth' = 'auto'
): void {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, conditional)
}
