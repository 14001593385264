import React, { VoidFunctionComponent, useState } from 'react'

import { ResetButton, Stack, Text, Icon } from '@mindfulchefuk/design-system'
import { FooterLink } from '@mindfulchefuk/features/Navigation/components/Footer/FooterLink'
import { ListItem } from '@mindfulchefuk/features/Navigation/components/Footer/ListItem'

interface LinkBlock {
  title: string
  order: number
  items?: LinkData[]
}
interface LinkData {
  title: string
  path: string
  order: number
  target: string
  reference: string
}

interface Props {
  block: LinkBlock
  onClick: (linkId: string) => void
}

export const FooterBlock: VoidFunctionComponent<Props> = ({
  block,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <ListItem position="relative">
      <ResetButton onClick={() => setIsOpen((val) => !val)} display="flex">
        <Text variant={{ base: 'body-xl-500-caps', md: 'body-sm-500-caps' }}>
          {block.title}
        </Text>
        <Icon
          type={isOpen ? 'chevron-up' : 'chevron-down'}
          size={20}
          marginTop={6}
          marginLeft={8}
          display={{ md: 'none' }}
        />
      </ResetButton>
      {block.items && (
        <Stack
          my={8}
          spacing={8}
          as="ul"
          p={0}
          align="start"
          display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
        >
          {block.items.map((link) => {
            return (
              <ListItem
                onClick={() => onClick(link.reference)}
                key={link.order}
              >
                <FooterLink
                  reference={link.reference}
                  openInNewWindow={link.target === '_blank'}
                  href={link.path}
                >
                  {link.title}
                </FooterLink>
              </ListItem>
            )
          })}
        </Stack>
      )}
    </ListItem>
  )
}
