import { VouchersRedemption } from '@mindfulchefuk/api-client'
import { Redemptions } from '@mindfulchefuk/features/Subscription/interfaces'
import { transformRedemptions } from '@mindfulchefuk/features/Subscription/transforms/transformRedemptions'

export const getRedemptions = async (): Promise<Redemptions> => {
  const { data } = await VouchersRedemption.includes([{ promotion: 'channel' }])
    .order({ created_at: 'desc' })
    .all()

  return transformRedemptions(data)
}
