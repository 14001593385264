import React, { VoidFunctionComponent } from 'react'
import { Box, Svg } from '@mindfulchefuk/design-system'

export const BCorpLogo: VoidFunctionComponent = () => (
  <Box maxWidth={44}>
    <Svg viewBox="0 0 97 164" width={44} height={64}>
      <path
        d="M28.27 47.92H48.25C53.11 47.92 59.52 47.92 62.83 49.93C66.46 52.09 69.31 56.02 69.31 61.43C69.31 67.29 66.23 71.69 61.13 73.77V73.93C67.92 75.32 71.62 80.41 71.62 87.2C71.62 95.3 65.83 103.02 54.88 103.02H28.27V47.92ZM35.6 71.6H50.27C58.6 71.6 62 68.59 62 62.8C62 55.16 56.6 54.08 50.27 54.08H35.6V71.6ZM35.6 96.83H53.97C60.29 96.83 64.31 92.97 64.31 86.96C64.31 79.78 58.52 77.78 52.35 77.78H35.6V96.83Z"
        fill="currentColor"
      />
      <path
        d="M90.37 75.4801C90.37 98.6201 71.61 117.38 48.46 117.38C25.32 117.38 6.55996 98.6201 6.55996 75.4801C6.55996 52.3401 25.32 33.5801 48.46 33.5801C71.61 33.5901 90.37 52.3501 90.37 75.4801ZM48.47 27.2001C21.82 27.2001 0.209961 48.8101 0.209961 75.4601C0.209961 102.11 21.82 123.72 48.47 123.72C75.13 123.72 96.74 102.11 96.74 75.4601C96.74 48.8101 75.13 27.2001 48.47 27.2001Z"
        fill="currentColor"
      />
      <path
        d="M96.6301 133.91H0.300049V140.24H96.6301V133.91Z"
        fill="currentColor"
      />
      <path
        d="M93.62 126.94C93.74 126.94 93.85 126.93 93.96 126.93C94.06 126.92 94.16 126.9 94.24 126.87C94.33 126.83 94.39 126.77 94.44 126.7C94.49 126.62 94.51 126.52 94.51 126.39C94.51 126.28 94.49 126.18 94.45 126.12C94.41 126.05 94.35 126 94.28 125.97C94.21 125.93 94.14 125.91 94.04 125.89C93.95 125.88 93.87 125.88 93.77 125.88H93.08V126.95H93.62V126.94ZM93.85 125.44C94.25 125.44 94.54 125.52 94.74 125.68C94.93 125.84 95.03 126.08 95.03 126.41C95.03 126.72 94.94 126.93 94.78 127.08C94.61 127.22 94.4 127.3 94.14 127.33L95.1 128.81H94.54L93.62 127.36H93.07V128.81H92.54V125.45H93.85V125.44ZM91.49 128.09C91.6 128.39 91.77 128.66 91.99 128.88C92.2 129.1 92.45 129.27 92.74 129.4C93.02 129.52 93.34 129.58 93.67 129.58C94.01 129.58 94.32 129.52 94.6 129.4C94.89 129.27 95.14 129.1 95.35 128.88C95.56 128.66 95.73 128.4 95.84 128.09C95.96 127.79 96.03 127.47 96.03 127.12C96.03 126.77 95.97 126.45 95.84 126.15C95.72 125.85 95.55 125.59 95.35 125.37C95.13 125.15 94.88 124.98 94.6 124.86C94.32 124.74 94 124.67 93.67 124.67C93.34 124.67 93.02 124.74 92.74 124.86C92.45 124.99 92.19 125.16 91.99 125.37C91.77 125.59 91.61 125.85 91.49 126.15C91.37 126.45 91.31 126.76 91.31 127.12C91.3 127.46 91.36 127.79 91.49 128.09ZM91.01 125.98C91.16 125.63 91.37 125.34 91.63 125.08C91.89 124.82 92.2 124.63 92.55 124.48C92.9 124.33 93.28 124.26 93.66 124.26C94.06 124.26 94.43 124.33 94.79 124.48C95.13 124.63 95.44 124.82 95.69 125.08C95.95 125.34 96.17 125.64 96.31 125.98C96.47 126.33 96.54 126.71 96.54 127.12C96.54 127.53 96.47 127.91 96.31 128.26C96.16 128.62 95.95 128.92 95.69 129.18C95.43 129.45 95.13 129.64 94.79 129.79C94.43 129.93 94.06 130 93.66 130C93.27 130 92.9 129.93 92.55 129.79C92.2 129.64 91.89 129.45 91.63 129.18C91.37 128.92 91.16 128.62 91.01 128.26C90.85 127.91 90.79 127.53 90.79 127.12C90.78 126.71 90.85 126.33 91.01 125.98Z"
        fill="currentColor"
      />
      <path
        d="M11.98 5.98004C11.75 5.61004 11.47 5.29004 11.13 5.02004C10.79 4.74004 10.4 4.53004 9.97005 4.37004C9.54005 4.22004 9.09005 4.14004 8.62005 4.14004C7.76005 4.14004 7.03005 4.31004 6.42005 4.64004C5.82005 4.97004 5.33005 5.42004 4.96005 5.98004C4.59005 6.54004 4.31005 7.18004 4.14005 7.90004C3.97005 8.62004 3.88005 9.36004 3.88005 10.12C3.88005 10.85 3.96005 11.56 4.14005 12.25C4.31005 12.94 4.58005 13.56 4.96005 14.12C5.33005 14.67 5.82005 15.12 6.42005 15.45C7.02005 15.78 7.75005 15.95 8.62005 15.95C9.79005 15.95 10.7101 15.59 11.3601 14.88C12.0201 14.16 12.42 13.22 12.57 12.05H16.28C16.18 13.14 15.9301 14.12 15.5201 15C15.1101 15.88 14.58 16.63 13.91 17.24C13.24 17.86 12.46 18.33 11.57 18.66C10.68 18.98 9.69005 19.15 8.62005 19.15C7.29005 19.15 6.09005 18.92 5.02005 18.45C3.95005 17.99 3.06005 17.35 2.32005 16.53C1.59005 15.72 1.03005 14.76 0.640049 13.66C0.250049 12.56 0.0500488 11.38 0.0500488 10.11C0.0500488 8.81004 0.240049 7.60004 0.640049 6.49004C1.03005 5.38004 1.59005 4.40004 2.32005 3.58004C3.05005 2.75004 3.95005 2.10004 5.02005 1.63004C6.08005 1.16004 7.28005 0.920044 8.62005 0.920044C9.58005 0.920044 10.49 1.06004 11.34 1.33004C12.19 1.61004 12.9601 2.01004 13.6301 2.54004C14.3001 3.07004 14.86 3.72004 15.3 4.50004C15.74 5.28004 16.0201 6.18004 16.1301 7.18004H12.42C12.35 6.74004 12.21 6.34004 11.98 5.98004Z"
        fill="currentColor"
      />
      <path
        d="M21.27 15.7201C21.79 16.2201 22.5399 16.4801 23.5099 16.4801C24.2099 16.4801 24.81 16.3101 25.32 15.9601C25.82 15.6101 26.13 15.2401 26.25 14.8501H29.2999C28.8099 16.3601 28.0599 17.4501 27.0499 18.1001C26.0399 18.7501 24.8199 19.0801 23.3899 19.0801C22.3999 19.0801 21.4999 18.9201 20.7099 18.6001C19.9099 18.2801 19.24 17.8301 18.68 17.2501C18.13 16.6701 17.7 15.9701 17.4 15.1501C17.1 14.3401 16.95 13.4401 16.95 12.4701C16.95 11.5301 17.1 10.6501 17.41 9.84007C17.72 9.03007 18.1599 8.32007 18.7299 7.73007C19.2999 7.14007 19.98 6.67007 20.77 6.33007C21.56 5.99007 22.4299 5.82007 23.3899 5.82007C24.4599 5.82007 25.4 6.03007 26.2 6.44007C27 6.86007 27.65 7.41007 28.16 8.11007C28.67 8.81007 29.04 9.61007 29.27 10.5001C29.5 11.3901 29.5799 12.3301 29.5099 13.3101H20.41C20.46 14.4001 20.74 15.2201 21.27 15.7201ZM25.18 9.09007C24.76 8.64007 24.13 8.41007 23.29 8.41007C22.74 8.41007 22.28 8.50007 21.91 8.69007C21.54 8.88007 21.2499 9.11007 21.0299 9.38007C20.8099 9.66007 20.66 9.95007 20.57 10.2601C20.48 10.5701 20.43 10.8401 20.41 11.0901H26.0499C25.8899 10.2101 25.6 9.54007 25.18 9.09007Z"
        fill="currentColor"
      />
      <path
        d="M34.3701 6.13004V8.47004H34.4201C34.5801 8.08004 34.8001 7.72004 35.0801 7.38004C35.3601 7.05004 35.6701 6.76004 36.0301 6.53004C36.3901 6.30004 36.7701 6.11004 37.1801 5.98004C37.5901 5.85004 38.0101 5.79004 38.4501 5.79004C38.6801 5.79004 38.9301 5.83004 39.2101 5.91004V9.13004C39.0501 9.10004 38.8501 9.07004 38.6201 9.05004C38.3901 9.03004 38.1701 9.01004 37.9601 9.01004C37.3301 9.01004 36.7901 9.12004 36.3501 9.33004C35.9101 9.54004 35.5601 9.83004 35.2901 10.2C35.0201 10.57 34.8301 10.99 34.7201 11.48C34.6101 11.97 34.5501 12.5 34.5501 13.07V18.76H31.0901V6.13004H34.3701Z"
        fill="currentColor"
      />
      <path
        d="M48.4701 6.12998V8.44999H45.9301V14.7C45.9301 15.29 46.0301 15.68 46.2201 15.87C46.4101 16.06 46.8101 16.16 47.3901 16.16C47.5901 16.16 47.7701 16.15 47.9501 16.14C48.1301 16.12 48.3001 16.1 48.4601 16.07V18.75C48.1701 18.8 47.8401 18.83 47.4801 18.85C47.1201 18.87 46.7701 18.88 46.4301 18.88C45.8901 18.88 45.3801 18.84 44.9101 18.77C44.4301 18.7 44.0101 18.56 43.6401 18.34C43.2701 18.13 42.9901 17.83 42.7701 17.44C42.5601 17.05 42.4501 16.54 42.4501 15.9V8.45998H40.3501V6.13998H42.4501V2.35999H45.9101V6.13998H48.4701V6.12998Z"
        fill="currentColor"
      />
      <path
        d="M50.49 4.18007V1.32007H53.95V4.18007H50.49ZM53.95 6.13007V18.7401H50.49V6.13007H53.95Z"
        fill="currentColor"
      />
      <path
        d="M55.3301 8.45007V6.13007H57.4001V5.15007C57.4001 4.03007 57.7501 3.11007 58.4501 2.39007C59.1501 1.67007 60.2101 1.32007 61.6201 1.32007C61.9301 1.32007 62.2401 1.33007 62.5501 1.36007C62.8601 1.38007 63.1601 1.41007 63.4501 1.42007V4.01007C63.0401 3.96007 62.6201 3.94007 62.1801 3.94007C61.7101 3.94007 61.3701 4.05007 61.1701 4.27007C60.9701 4.49007 60.8701 4.86007 60.8701 5.38007V6.14007H63.2601V8.46007H60.8701V18.7601H57.4001V8.46007H55.3301V8.45007Z"
        fill="currentColor"
      />
      <path
        d="M65.6001 4.18007V1.32007H69.0601V4.18007H65.6001ZM69.0701 6.13007V18.7401H65.6001V6.13007H69.0701Z"
        fill="currentColor"
      />
      <path
        d="M74.53 15.7201C75.05 16.2201 75.8 16.48 76.77 16.48C77.47 16.48 78.07 16.31 78.58 15.96C79.08 15.61 79.39 15.24 79.51 14.85H82.56C82.07 16.36 81.32 17.45 80.32 18.1C79.31 18.75 78.09 19.0801 76.66 19.0801C75.67 19.0801 74.77 18.92 73.98 18.6C73.18 18.28 72.51 17.83 71.95 17.25C71.4 16.67 70.97 15.9701 70.67 15.1501C70.37 14.3401 70.22 13.44 70.22 12.47C70.22 11.53 70.38 10.65 70.68 9.84005C70.99 9.00005 71.43 8.30005 72 7.71005C72.57 7.12005 73.25 6.65005 74.04 6.31005C74.83 5.97005 75.7 5.80005 76.66 5.80005C77.73 5.80005 78.67 6.01005 79.47 6.42005C80.27 6.84005 80.92 7.39005 81.43 8.09005C81.94 8.79005 82.31 9.59005 82.54 10.48C82.77 11.37 82.85 12.3101 82.79 13.2901H73.69C73.73 14.4001 74.01 15.2201 74.53 15.7201ZM78.45 9.09005C78.04 8.64005 77.4 8.41005 76.56 8.41005C76.01 8.41005 75.55 8.50005 75.18 8.69005C74.81 8.88005 74.52 9.11005 74.3 9.38005C74.08 9.66005 73.93 9.95005 73.84 10.26C73.75 10.57 73.7 10.84 73.68 11.09H79.31C79.15 10.21 78.86 9.54005 78.45 9.09005Z"
        fill="currentColor"
      />
      <path
        d="M93.3001 17.1401C92.8901 17.8201 92.3601 18.3201 91.7001 18.6201C91.0401 18.9201 90.3001 19.0701 89.4701 19.0701C88.5301 19.0701 87.7001 18.8901 86.9801 18.5201C86.2601 18.1501 85.6701 17.6601 85.2101 17.0301C84.7501 16.4001 84.4001 15.6801 84.1601 14.8701C83.9201 14.0601 83.8101 13.2101 83.8101 12.3301C83.8101 11.4801 83.9301 10.6701 84.1601 9.88008C84.3901 9.09008 84.7501 8.40008 85.2101 7.79008C85.6701 7.19008 86.2601 6.70008 86.9501 6.34008C87.6501 5.97008 88.4601 5.79008 89.3901 5.79008C90.1401 5.79008 90.8501 5.95008 91.5201 6.27008C92.2001 6.59008 92.7301 7.05008 93.1201 7.67008H93.1701V1.33008H96.6301V18.7501H93.3401V17.1401H93.3001ZM93.1501 10.9001C93.0501 10.4201 92.8901 10.0001 92.6501 9.63008C92.4101 9.26008 92.1101 8.97008 91.7301 8.74008C91.3601 8.51008 90.8801 8.40008 90.3201 8.40008C89.7601 8.40008 89.2701 8.51008 88.8801 8.74008C88.4901 8.97008 88.1801 9.27008 87.9401 9.64008C87.7101 10.0101 87.5401 10.4401 87.4301 10.9201C87.3201 11.4001 87.2701 11.9001 87.2701 12.4201C87.2701 12.9101 87.3301 13.4001 87.4401 13.8801C87.5501 14.3701 87.7401 14.8001 87.9901 15.1901C88.2401 15.5701 88.5601 15.8801 88.9401 16.1201C89.3201 16.3601 89.7801 16.4701 90.3201 16.4701C90.8901 16.4701 91.3601 16.3601 91.7501 16.1301C92.1301 15.9001 92.4401 15.6001 92.6701 15.2101C92.9001 14.8301 93.0601 14.3901 93.1601 13.9101C93.2601 13.4201 93.3101 12.9201 93.3101 12.4001C93.3001 11.8801 93.2501 11.3801 93.1501 10.9001Z"
        fill="currentColor"
      />
      <path
        d="M8.75004 152.67C8.58004 151.45 7.41004 150.52 6.07004 150.52C3.65004 150.52 2.74004 152.58 2.74004 154.73C2.74004 156.77 3.65004 158.83 6.07004 158.83C7.72004 158.83 8.65004 157.7 8.85004 156.08H11.47C11.19 159.14 9.08004 161.1 6.07004 161.1C2.27004 161.1 0.0400391 158.26 0.0400391 154.73C0.0400391 151.09 2.27004 148.25 6.07004 148.25C8.77004 148.25 11.03 149.83 11.36 152.67H8.75004Z"
        fill="currentColor"
      />
      <path
        d="M17.1 151.69C19.9 151.69 21.7 153.54 21.7 156.38C21.7 159.2 19.9 161.05 17.1 161.05C14.32 161.05 12.51 159.19 12.51 156.38C12.51 153.55 14.32 151.69 17.1 151.69ZM17.1 159.22C18.77 159.22 19.26 157.79 19.26 156.39C19.26 154.96 18.76 153.54 17.1 153.54C15.45 153.54 14.95 154.97 14.95 156.39C14.95 157.79 15.45 159.22 17.1 159.22Z"
        fill="currentColor"
      />
      <path
        d="M23.21 151.92H25.53V153.57H25.57C26.01 152.45 27.22 151.68 28.41 151.68C28.58 151.68 28.79 151.71 28.94 151.76V154.03C28.71 153.98 28.35 153.95 28.06 153.95C26.27 153.95 25.65 155.24 25.65 156.8V160.8H23.21V151.92Z"
        fill="currentColor"
      />
      <path
        d="M29.8901 151.93H32.2101V153.06H32.2401C32.8201 152.12 33.7801 151.69 34.8801 151.69C37.6701 151.69 38.9201 153.94 38.9201 156.46C38.9201 158.83 37.6201 161.05 35.0201 161.05C33.9601 161.05 32.9401 160.59 32.3601 159.71H32.3201V163.94H29.8801V151.93H29.8901ZM36.4801 156.4C36.4801 154.99 35.9101 153.53 34.3501 153.53C32.7501 153.53 32.2401 154.96 32.2401 156.4C32.2401 157.84 32.7901 159.22 34.3701 159.22C35.9701 159.22 36.4801 157.84 36.4801 156.4Z"
        fill="currentColor"
      />
      <path
        d="M44.2699 151.69C47.0699 151.69 48.8799 153.54 48.8799 156.38C48.8799 159.2 47.0699 161.05 44.2699 161.05C41.4899 161.05 39.6899 159.19 39.6899 156.38C39.6899 153.55 41.4899 151.69 44.2699 151.69ZM44.2699 159.22C45.9399 159.22 46.4399 157.79 46.4399 156.39C46.4399 154.96 45.9399 153.54 44.2699 153.54C42.6199 153.54 42.1199 154.97 42.1199 156.39C42.1299 157.79 42.6299 159.22 44.2699 159.22Z"
        fill="currentColor"
      />
      <path
        d="M50.3901 151.92H52.7101V153.57H52.7501C53.1901 152.45 54.4001 151.68 55.5801 151.68C55.7501 151.68 55.9601 151.71 56.1101 151.76V154.03C55.8901 153.98 55.5301 153.95 55.2301 153.95C53.4401 153.95 52.8201 155.24 52.8201 156.8V160.8H50.3801V151.92H50.3901Z"
        fill="currentColor"
      />
      <path
        d="M56.8301 154.66C56.9701 152.37 59.0101 151.69 61.0101 151.69C62.7801 151.69 64.9101 152.08 64.9101 154.21V158.83C64.9101 159.64 64.9901 160.44 65.2201 160.81H62.7401C62.6601 160.53 62.5801 160.24 62.5701 159.95C61.8001 160.76 60.6701 161.05 59.5801 161.05C57.9001 161.05 56.5601 160.21 56.5601 158.39C56.5601 156.38 58.0701 155.9 59.5801 155.69C61.0801 155.47 62.4701 155.52 62.4701 154.52C62.4701 153.47 61.7401 153.32 60.8901 153.32C59.9601 153.32 59.3601 153.7 59.2701 154.66H56.8301ZM62.4601 156.47C62.0401 156.83 61.1901 156.85 60.4301 156.99C59.6701 157.14 58.9901 157.4 58.9901 158.3C58.9901 159.21 59.6901 159.43 60.4901 159.43C62.3901 159.43 62.4701 157.92 62.4701 157.39V156.47H62.4601Z"
        fill="currentColor"
      />
      <path
        d="M69.8302 151.93H71.6202V153.56H69.8302V157.96C69.8302 158.79 70.0402 158.99 70.8602 158.99C71.1202 158.99 71.3602 158.97 71.6202 158.92V160.83C71.2102 160.9 70.6702 160.92 70.1902 160.92C68.7002 160.92 67.3902 160.57 67.3902 158.81V153.57H65.9102V151.94H67.3902V149.28H69.8302V151.93Z"
        fill="currentColor"
      />
      <path
        d="M75.9 150.56H73.46V148.55H75.9V150.56ZM73.46 151.93H75.9V160.81H73.46V151.93Z"
        fill="currentColor"
      />
      <path
        d="M82.12 151.69C84.92 151.69 86.73 153.54 86.73 156.38C86.73 159.2 84.92 161.05 82.12 161.05C79.34 161.05 77.54 159.19 77.54 156.38C77.54 153.55 79.34 151.69 82.12 151.69ZM82.12 159.22C83.79 159.22 84.29 157.79 84.29 156.39C84.29 154.96 83.79 153.54 82.12 153.54C80.47 153.54 79.97 154.97 79.97 156.39C79.97 157.79 80.47 159.22 82.12 159.22Z"
        fill="currentColor"
      />
      <path
        d="M88.3 151.93H90.62V153.17H90.67C91.29 152.17 92.35 151.69 93.39 151.69C95.98 151.69 96.64 153.15 96.64 155.35V160.81H94.2V155.79C94.2 154.33 93.77 153.61 92.64 153.61C91.31 153.61 90.75 154.35 90.75 156.16V160.82H88.31V151.93H88.3Z"
        fill="currentColor"
      />
    </Svg>
  </Box>
)
