const daysOfTheWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const prettifyWeekdays = (weekdays: string[]): string => {
  return daysOfTheWeek
    .filter((day) => weekdays.includes(day))
    .map((day) => `${day}s`)
    .join(', ')
    .replace(/,([^,]*)$/, ` and${'$1'}`)
}
