import React from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'

export const NotificationErrorDateInvalid = () => (
  <BannerNotification
    variant="error"
    id="error-date-invalid"
    title="Delivery date invalid"
    isDismissable
  >
    The delivery date you had selected is unavailable, please select a new date.
  </BannerNotification>
)
