import { Box } from '@mindfulchefuk/design-system/layout/Box'
import React, { VoidFunctionComponent } from 'react'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { NavCTA } from '@mindfulchefuk/features/Navigation/components/Header/shared/NavCTA'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'

interface NavSignUpCTAProps {
  onClick?: () => void
  fullWidth?: boolean
}

export const NavChooseRecipesCTA: VoidFunctionComponent<NavSignUpCTAProps> = ({
  onClick,
  fullWidth,
}) => {
  const { chooseRecipesQueryParams } = useCMSHomepageVariant()

  return (
    <NavCTA
      variant="primary"
      testId="nav-cta-sign-up"
      onClick={onClick}
      fullWidth={fullWidth}
      href={routesConfig.chooseRecipes.pathname + chooseRecipesQueryParams}
    >
      <Box as="span">Choose recipes</Box>
    </NavCTA>
  )
}
