import { useRouter } from 'next/router'
import { HomepageVariantPath } from '@mindfulchefuk/features/Homepage/interfaces'
import { CMSHomepageVariant } from '@mindfulchefuk/features/CMS/interfaces'
import { useCMSPage } from '@mindfulchefuk/features/CMS/hooks/useCMSPage'
import { homepageVariantFallback } from '@mindfulchefuk/features/Homepage/data/homepage-variant'

type ChooseRecipesQueryParams = {
  [key in HomepageVariantPath]?: string
}

const chooseRecipeCTAQueryParams: ChooseRecipesQueryParams = {
  'vegan-recipe-boxes': '?initialMealPlan=Plant-Based',
  'family-recipe-boxes': '?initialPortionCount=4',
}

export const useCMSHomepageVariant = (): CMSHomepageVariant => {
  const router = useRouter()
  const { query } = router
  const { basePage } = query

  const { data } = useCMSPage('page-homepage')

  const variant = getActiveVariant(data?.variants, basePage)

  // Explicit fallback for optional components
  if (!variant.howItWorks)
    variant.howItWorks = homepageVariantFallback.howItWorks
  if (!variant.standout) variant.standout = homepageVariantFallback.standout

  return {
    ...variant,
    chooseRecipesQueryParams:
      chooseRecipeCTAQueryParams[
        variant.path as keyof ChooseRecipesQueryParams
      ] || '',
  }
}

export function getActiveVariant(
  variants: CMSHomepageVariant[],
  currentPath: string | string[]
) {
  if (!variants) return homepageVariantFallback

  const currentVariant = variants?.find((v) => v.path === currentPath)
  if (currentVariant) return currentVariant

  const defaultVariant = variants?.find((v) => v.path === '/')
  return defaultVariant || homepageVariantFallback
}
