import { RootState } from '@mindfulchefuk/types/store'
import { useSelector } from 'react-redux'
import { PriceText } from '@mindfulchefuk/design-system'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'

export const NotificationSuccessOrderCancelled = () => {
  const id = 'success-order-cancelled'

  const date = useSelector(
    (store: RootState) => store.notifications.meta[id].date
  )

  const credit = useSelector(
    (store: RootState) => store.notifications.meta[id].credit
  )

  const hasRecipes = useSelector(
    (store: RootState) => store.notifications.meta[id].hasRecipes
  )

  return (
    <BannerNotification
      variant="success"
      title="Order cancelled"
      id={id}
      isDismissable
      shouldAutoDismiss
    >
      Your Ready to Go range order for {date} has been cancelled and a credit of{' '}
      <PriceText as="span" color="inherit" price={credit} /> has been applied to
      your account for use against future purchases.{' '}
      {hasRecipes && (
        <>
          Note that you still have a recipe box scheduled for delivery on this
          date.
        </>
      )}
    </BannerNotification>
  )
}
