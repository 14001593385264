import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'
import { HotJarEvents } from './events'

export const triggerHotJarEvent = (event: HotJarEvents) => {
  const isHotJarEnabled = process.env.NEXT_PUBLIC_HOTJAR_ENABLED

  if (isObjTrue(isHotJarEnabled)) {
    window?.hj?.('event', event)
  }
}
