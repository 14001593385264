import { SubscriptionsSubscription } from '@mindfulchefuk/api-client'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { transformSubscription } from '@mindfulchefuk/features/Subscription/transforms/transformSubscription'

export const getSubscription = async (
  customerId: string
): Promise<Subscription> => {
  const { data } = await SubscriptionsSubscription.find(customerId)

  return transformSubscription(data)
}
