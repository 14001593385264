import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'
import {
  CMSPage,
  CMSPageHomepage,
} from '@mindfulchefuk/features/CMS/interfaces'
import { transformBanners } from '@mindfulchefuk/features/CMS/transformers/transformBanners'

export type CMSPageApiPathWithBanners =
  | 'page-homepage'
  | 'page-recipe-onboarding'
  | 'page-shop-onboarding'
  | 'page-account-delivery'
  | 'page-account-homepage'

export type CMSPageApiPath =
  | CMSPageApiPathWithBanners
  | 'page-referral'
  | 'page-dual-flow'
  | 'page-account-preference'

export const getCMSPage = async (
  page: CMSPageApiPath
): Promise<CMSPage | CMSPageHomepage | null> => {
  if (isObjTrue(process.env.BUILD_PHASE)) {
    return null
  }

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_CMS_API_BASE_URL}/${page}?populate=deep&simple=true`,
      {
        keepalive: true,
      }
    )

    const data = await response.json()

    if (data.banners) data.banners = transformBanners(data.banners)

    return data
  } catch (error) {
    errorHandler(error, { suppress: true })

    return null
  }
}
