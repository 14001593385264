import { RootState } from '@mindfulchefuk/types/store'
import { useSelector } from 'react-redux'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'

export const NotificationSuccessVoucherApplied = () => {
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )

  const data = getDiscountConfig(savedVoucherCode)

  if (!savedVoucherCode || !data || isAuthenticated()) return null

  return (
    <BannerNotification
      variant="success"
      title={`Your discount code "${savedVoucherCode}" has been applied`}
      id="success-voucher-applied"
      data-testid="success-voucher-applied"
    >
      {data.totalDiscountSentence}
    </BannerNotification>
  )
}
