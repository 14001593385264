import React, { FunctionComponent, useState } from 'react'
import { Box, Stack } from '@mindfulchefuk/design-system/layout'
import { Text } from '@mindfulchefuk/design-system/Text'
import { Image } from '@mindfulchefuk/design-system/Image'
import { theme } from '@mindfulchefuk/design-system/system/theme'
import styled from '@emotion/styled'
import NextLink from 'next/link'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

type Props = {
  imageUrl: string
  href: string
  title: string
  description?: string
  focus?: boolean
  isDynamic?: boolean
  external?: boolean
}

const ImageContainer = styled(Image)`
  object-fit: cover;
  height: 100%;
`

const hoverBox = {
  boxShadow: `${theme.shadows.standard}`,
  transform: 'scale(1.01)',
}
const hoverText = {
  textDecoration: 'underline',
  textUnderlineOffset: '2px',
}

export const MegaNavImage: FunctionComponent<Props> = ({
  imageUrl,
  title,
  description,
  focus = false,
  isDynamic = false,
  href,
  external = false,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const logNavImageClicked = (variant: 'image' | 'text') =>
    logEvent({
      category: ANALYTICS_CATEGORIES.navigation,
      action:
        variant === 'text' ? 'nav-image-text-clicked' : 'nav-image-clicked',
      label: title,
    })

  const imageLink = (
    <Stack
      direction="column"
      spacing={10}
      cursor="pointer"
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        width={256}
        height={208}
        style={(isDynamic && focus) || isHovered ? hoverBox : {}}
        onClick={() => logNavImageClicked('image')}
      >
        <ImageContainer resolve src={imageUrl} alt={title} />
        {focus && description && isDynamic && (
          <Box p={8} position="absolute" bottom={0} bg="white.80">
            <Text variant="body-sm" color="aubergine">
              {description}
            </Text>
          </Box>
        )}
      </Box>
      <Text
        variant="body-sm-500-caps"
        textAlign="center"
        style={isHovered ? hoverText : {}}
        onClick={() => logNavImageClicked('text')}
        color="aubergine"
      >
        {title}
      </Text>
    </Stack>
  )

  return external ? (
    <a href={href}>{imageLink}</a>
  ) : (
    <NextLink href={href} passHref>
      {imageLink}
    </NextLink>
  )
}
