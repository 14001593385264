import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import React from 'react'

export const NotificationSuccessResetLinkSent = () => (
  <BannerNotification
    variant="success"
    title="Reset link sent"
    id="success-reset-link-sent"
    isDismissable
    shouldAutoDismiss
  >
    A link to reset your password has been sent, if this email exists
  </BannerNotification>
)
