import React, { useState, VoidFunctionComponent } from 'react'
import { Box, Stack } from '@mindfulchefuk/design-system'
import debounce from 'lodash/debounce'
import {
  MegaNav,
  NavItem,
} from '@mindfulchefuk/features/Navigation/components/Header/shared'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'

interface Props {
  activeItem: string
  setActiveItem: (item: string) => void
  links: NavLinkData[]
}

export const SignedOutNavDesktop: VoidFunctionComponent<Props> = ({
  activeItem,
  setActiveItem,
  links,
}: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 400)

  const handleOnMouseLeave = () => {
    setIsHovered(false)
    debouncedHandleMouseEnter.cancel()
  }

  const handleCloseEverything = () => {
    setActiveItem(null)
  }

  return (
    <Stack
      display={{ base: 'none', lg: 'flex' }}
      ml={20}
      direction="row"
      spacing={20}
      onMouseLeave={() => {
        setActiveItem(null)
        handleOnMouseLeave()
      }}
    >
      {links.map((link) => {
        if (link.subNav) {
          const isActive = activeItem === link.id
          return (
            <Box key={link.id}>
              <NavItem
                hasSubMenu
                id={link.id}
                href={link.subNav[0].href}
                active={isActive}
                onMouseEnter={() => {
                  setActiveItem(link.id)
                  debouncedHandleMouseEnter()
                }}
              >
                {link.label}
              </NavItem>
              {isActive && isHovered && (
                <MegaNav {...link} handleClose={handleCloseEverything} />
              )}
            </Box>
          )
        }

        return (
          <NavItem
            id={link.id}
            key={link.id}
            href={link.href}
            hasSubMenu={false}
            onClick={handleCloseEverything}
            onMouseEnter={() => setActiveItem(null)}
          >
            {link.label}
          </NavItem>
        )
      })}
    </Stack>
  )
}
