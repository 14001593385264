import { SubscriptionsFeedback } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

export interface CancelSubscriptionFeedbackParams {
  customerId: string
  subscriptionId: string
  description: string
  competitor: boolean
  reason: string
  subreason: string
}

export const cancelSubscriptionFeedback = async (
  feedback: CancelSubscriptionFeedbackParams
) => {
  try {
    const subscriptionsFeedback = new SubscriptionsFeedback({
      customer_id: feedback.customerId,
      subscription_id: feedback.subscriptionId,
      description: feedback.description,
      competitor: feedback.competitor,
      reason: feedback.reason,
      subreason: feedback.subreason,
    })
    const success = await subscriptionsFeedback.save()

    if (!success) throw new Error('Failed to persist feedback')
  } catch (error) {
    errorHandler(error)
  }
}
