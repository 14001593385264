import {
  updateSubscription,
  UpdateSubscriptionParams,
} from '@mindfulchefuk/features/Subscription/services/updateSubscription'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from '@tanstack/react-query'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'

export const useUpdateSubscription = (
  mutateOptions: UseMutationOptions<
    Subscription,
    Error,
    UpdateSubscriptionParams
  > = {}
) => {
  const queryClient = useQueryClient()
  const customerId = getCustomerId()

  return useMutation({
    mutationFn: (params: UpdateSubscriptionParams) =>
      updateSubscription(params, customerId),
    ...mutateOptions,
    onSuccess: async (data, variables, context) => {
      queryClient.setQueryData(['subscription', { customerId }], data)
      await queryClient.invalidateQueries({
        queryKey: ['deliveries', 'subscription'],
      })

      mutateOptions.onSuccess?.(data, variables, context)
    },
  })
}
