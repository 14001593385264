import {
  CardDetails,
  getPaymentMethod,
} from '@mindfulchefuk/services/getPaymentMethod'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { JsonapiError } from 'spraypaint/lib/jsonapi-spec'
import { STALE_10_MINS } from '@mindfulchefuk/constants'

export interface ExternalPaymentsPaymentMethodResult {
  data: {
    expiry_month: string
    expiry_year: string
    last_4: string
    primary: boolean
    scheme: string
  }
}

export const useGetCardDetails = (
  customerId: string,
  options: UseQueryOptions<CardDetails> = {}
) => {
  return useQuery<CardDetails, { response: JsonapiError }>(
    ['external-payments-payment-method', customerId],
    () => getPaymentMethod(customerId),
    {
      staleTime: STALE_10_MINS,
      ...options,
    }
  )
}
