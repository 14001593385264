import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getRedemptions } from '@mindfulchefuk/features/Subscription/services/getRedemptions'
import { Redemptions } from '@mindfulchefuk/features/Subscription/interfaces'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'

export const useGetRedemptions = (
  options: UseQueryOptions<Redemptions> = {}
) => {
  // uses the customer id for the cache as
  // the api returns user specific results
  // using the token on the http headers
  const customerId = getCustomerId()

  return useQuery({
    queryKey: ['redemptions', { customerId }],
    queryFn: () => getRedemptions(),
    enabled: !!customerId,
    staleTime: 10 * 1000, // 10 seconds
    ...options,
  })
}
