import React from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { ButtonLink } from '@mindfulchefuk/design-system/Link'

export const NotificationWarningVersionUpdate = () => (
  <BannerNotification
    id="warning-version-update"
    variant="warning"
    title="An update is fresh out of the oven!"
    isDismissable
  >
    It&apos;s best to{' '}
    <ButtonLink
      variant="body-md"
      onClick={() => window.location.reload()}
      color="inherit"
    >
      reload your browser
    </ButtonLink>{' '}
    window
  </BannerNotification>
)
