import React, { FC, useEffect, useRef, useState, VFC } from 'react'
import { Box, Button, Flex, Icon, Stack } from '@mindfulchefuk/design-system'
import NextLink from 'next/link'
import { useSetAtom } from 'jotai'
import { useGetCustomer } from '@mindfulchefuk/features/Customer'
import { selectFirstName } from '@mindfulchefuk/features/Customer/selectors'
import { signedInNav } from '@mindfulchefuk/features/Navigation/data'
import {
  IllustratedDropdown,
  IllustratedDropdownProps,
  NavItem,
  NavLink,
} from '@mindfulchefuk/features/Navigation/components/Header/shared'
import { PageSource, ProductType } from '@mindfulchefuk/analytics'
import { instrumentPlaceAnOrderClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentDashboard'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'
import { OneFeedsTwoCountBanner } from '@mindfulchefuk/features/Promotions/OneFeedsTwo/OneFeedsTwoCountBanner'
import { useGetCustomerDeliveryStats } from '@mindfulchefuk/features/Delivery/hooks/useGetCustomerDeliveryStats'
import { customerIdAtom } from '@mindfulchefuk/store/customer'

type TSignedInNavDesktop = {
  isNewCustomer: boolean
  firstAvailableDate: string
  onNavClick: (linkId: string) => void
  onNavExpand: (linkId: string) => void
  pageSource: PageSource
}

const HOVER_DELAY = 500

export const SignedInNavDesktop: VFC<TSignedInNavDesktop> = ({
  isNewCustomer,
  firstAvailableDate,
  onNavClick,
  onNavExpand,
  pageSource,
}) => {
  const [activeGroup, setActiveGroup] = useState<string | null>(null)
  const setCustomerIdAtom = useSetAtom(customerIdAtom)

  const groupRef = useRef(activeGroup)
  groupRef.current = activeGroup

  const handleClose = () => setActiveGroup(null)

  // Only call the onNavExpand callback:
  // after the HOVER_DELAY
  // if the activeGroup hasn't changed
  // This prevents the callback being spammed as
  // users scroll the cursor over the dropdowns
  useEffect(() => {
    const timer = setTimeout(() => {
      if (groupRef.current === activeGroup && activeGroup) {
        onNavExpand(activeGroup)
      }
    }, HOVER_DELAY)

    return () => clearTimeout(timer)
  }, [activeGroup, onNavExpand])

  const { data: firstName } = useGetCustomer({ select: selectFirstName })
  const { data: customerStats } = useGetCustomerDeliveryStats()

  const account = isNewCustomer
    ? signedInNav.onboardingAccount
    : signedInNav.account

  const orders = isNewCustomer
    ? signedInNav.onboardingRecipeBoxes
    : signedInNav.yourOrders

  const r2gData = signedInNav.readyToGo(firstAvailableDate)

  return (
    <Stack
      display={{ base: 'none', md: 'flex' }}
      direction="row"
      onMouseLeave={handleClose}
      flexGrow={1}
      position="relative"
      ml={20}
    >
      <Stack spacing={20} direction="row">
        <NavDesktopDropdown
          label={orders.label}
          id={orders.id}
          subNav={orders.subNav}
          activeGroup={activeGroup}
          onLinkClick={onNavClick}
          onOpen={setActiveGroup}
          onClose={handleClose}
          width={isNewCustomer ? 208 : 176}
          variant="cucumber"
        />
        {!isNewCustomer && (
          <NavItem
            id={signedInNav.yourRecipes.id}
            href={signedInNav.yourRecipes.href}
            onClick={onNavClick}
          >
            {signedInNav.yourRecipes.label}
          </NavItem>
        )}
        {!isNewCustomer && (
          <NavDesktopDropdown
            label={r2gData.label}
            id={r2gData.id}
            subNav={r2gData.subNav}
            activeGroup={activeGroup}
            onClose={handleClose}
            onLinkClick={(linkId) => {
              instrumentPlaceAnOrderClicked({
                isUpsell: 'false',
                pageSource,
                productCategory: linkId as ProductType,
                productType: 'ready to go',
                section: 'top nav',
              })

              onNavClick(linkId)
            }}
            onOpen={setActiveGroup}
            variant="almonds"
            width={224}
          />
        )}
        <NavItem
          id={signedInNav.giftCards.id}
          href={signedInNav.giftCards.href}
          onClick={onNavClick}
        >
          {signedInNav.giftCards.label}
        </NavItem>
      </Stack>

      <Box ml="auto">
        <NavDesktopDropdown
          label={firstName || account.label}
          id={account.id}
          icon={account.icon}
          subNav={account.subNav}
          activeGroup={activeGroup}
          onOpen={setActiveGroup}
          onClose={handleClose}
          onLinkClick={onNavClick}
          variant="aubergine"
          width={192}
          alignment="right"
        >
          <NextLink href={signedInNav.signOut.href} passHref>
            <Button
              mt={8}
              type="button"
              variant="secondary"
              size="small"
              as="a"
              fullWidth
              onClick={() => {
                window.sessionStorage.removeItem('isLogInDelinquentModalShown')
                onNavClick(signedInNav.signOut.id)
                setCustomerIdAtom('')
              }}
            >
              Sign out
            </Button>
          </NextLink>
          {customerStats && (
            <Box pt={16}>
              <OneFeedsTwoCountBanner
                count={customerStats.billedRecipesCount}
              />
            </Box>
          )}
        </NavDesktopDropdown>
      </Box>
    </Stack>
  )
}

interface NavDesktopDropdownProps {
  id: NavLinkData['id']
  label: NavLinkData['label']
  icon?: NavLinkData['icon']
  subNav: NavLinkData['subNav']

  width?: IllustratedDropdownProps['width']
  variant: IllustratedDropdownProps['variant']
  alignment?: IllustratedDropdownProps['alignment']

  activeGroup: string
  onLinkClick: (linkId: string) => void
  onClose: () => void
  onOpen: (groupId: string) => void
}

const NavDesktopDropdown: FC<NavDesktopDropdownProps> = ({
  children,
  onClose,
  onLinkClick,
  onOpen,
  activeGroup,
  id,
  icon,
  label,
  subNav,
  variant,
  width = 176,
  alignment,
}) => {
  const isOpen = activeGroup === id

  return (
    <NavItem
      hasSubMenu
      id={id}
      active={isOpen}
      onMouseEnter={() => onOpen(id)}
      onMouseLeave={onClose}
    >
      <IllustratedDropdown
        onToggle={onClose}
        isOpen={isOpen}
        width={width as IllustratedDropdownProps['width']}
        variant={variant}
        alignment={alignment}
        px={20}
        mt={0}
      >
        <Box textAlign="left">
          {subNav.map((link) => (
            <NavLink
              external={link.route === 'external'}
              key={link.id}
              label={link.label}
              id={link.id}
              href={link.href}
              onClick={(linkId) => {
                onLinkClick(linkId)
                onClose()
              }}
            />
          ))}
          {children}
        </Box>
      </IllustratedDropdown>
      <Flex align="center">
        {icon && <Icon type={icon} size={20} mr={6} />}
        <Box>{label}</Box>
      </Flex>
    </NavItem>
  )
}
