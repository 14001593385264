import React, { FunctionComponent, useEffect } from 'react'
import { motion, Variants } from 'framer-motion'
import styled from '@emotion/styled'
import { theme } from '@mindfulchefuk/design-system/system'
import { Box } from '@mindfulchefuk/design-system/layout'

const containerVariants: Variants = {
  hide: {
    opacity: 0,
    x: -100,
    transition: {
      duration: 0.1,
      when: 'beforeChildren',
    },
    transitionEnd: {},
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.2,
      delay: 0.1,
      when: 'beforeChildren',
    },
    transitionEnd: {},
  },
}

const Container = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 256px;
  background-color: ${theme.colors.salt};
  z-index: 40;
  will-change: transform, opacity;

  ${theme.media.lg} {
    display: none;
  }
`

const CloseOverlay = styled.div`
  display: block;
  left: 256px;
  width: 200vw;
  height: 200vh;
  position: fixed;
  top: -100vh;
  z-index: 30;
  transform: none;
  cursor: pointer;
  will-change: transform opacity;
  background-color: ${theme.colors['pepper-600']};
  opacity: 0.5;

  ${theme.media.lg} {
    display: none;
  }
`

type WrapperProps = {
  isOpen: boolean
  onClose: () => void
}

export const MobileNav: FunctionComponent<WrapperProps> = ({
  isOpen,
  children,
  onClose,
}) => {
  useEffect(() => {
    if (isOpen) document.body.classList.add('scrollLock')

    return () => document.body.classList.remove('scrollLock')
  }, [isOpen])
  return (
    <Container
      variants={containerVariants}
      initial="hide"
      animate={isOpen ? 'show' : 'hide'}
      style={{ pointerEvents: isOpen ? 'all' : 'none' }}
    >
      <CloseOverlay onClick={onClose} />
      <Box overflow="auto" pb={32} data-testid="mobile-nav">
        {children}
      </Box>
    </Container>
  )
}
