import { ORDER_RANGE, PAST_ORDER_RANGE } from '@mindfulchefuk/constants'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import toISODateFormat from '@mindfulchefuk/lib/toISODateFormat'
import { ShopOrder, useQueryShopOrders } from '@mindfulchefuk/query'
import { UseQueryOptions } from '@tanstack/react-query'
import { addDays, subDays } from 'date-fns'

export const useGetShopOrders = (
  options: UseQueryOptions<ShopOrder[]> = {}
) => {
  const customerId = getCustomerId()

  const today = new Date()
  const fromDate = toISODateFormat(subDays(today, PAST_ORDER_RANGE))
  const toDate = toISODateFormat(addDays(today, ORDER_RANGE))

  return useQueryShopOrders({ fromDate, toDate, customerId }, options)
}
