import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { getCMSGlobalContent } from '@mindfulchefuk/features/CMS/services/getCMSGlobalContent'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'
import { CMSGlobalContent } from '@mindfulchefuk/features/CMS/interfaces'

export const useCMSGlobalContent = <TData = CMSGlobalContent>(
  options: UseQueryOptions<CMSGlobalContent, unknown, TData> = {}
) => {
  return useQuery({
    queryKey: ['cms-global-content'],
    queryFn: () => getCMSGlobalContent(),
    staleTime: STALE_1_HOUR,
    ...options,
  })
}
