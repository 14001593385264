import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { CMSGlobalContent } from '@mindfulchefuk/features/CMS/interfaces'
import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'

export const getCMSGlobalContent = async (): Promise<CMSGlobalContent> => {
  if (isObjTrue(process.env.BUILD_PHASE)) {
    return null
  }
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_CMS_API_BASE_URL}/global-content?populate=deep`,
      {
        keepalive: true,
      }
    )
    const data = await response.json()

    return data
  } catch (error) {
    errorHandler(error, { suppress: true })
    return null
  }
}
