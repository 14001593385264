import React, { useState, VFC } from 'react'
import NextLink from 'next/link'
import { Flex, ButtonLink, Text, Box, Link } from '@mindfulchefuk/design-system'
import routesConfig from '@mindfulchefuk/config/routesConfig'

import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { triggerHotJarEvent } from '@mindfulchefuk/utils/analytics/hotJar/triggerHotJarEvent'
import { useRouter } from 'next/router'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'
import { useUpdateSubscription } from '@mindfulchefuk/features/Subscription/hooks/useUpdateSubscription'
import { RestartSubscriptionSuccessModal } from '@mindfulchefuk/features/Subscription/components/RestartSubscriptionSuccessModal'
import { SubscriptionIcons } from '@mindfulchefuk/features/Subscription/components/SubscriptionIcons'
import { ResumeSubscriptionModal } from '@mindfulchefuk/features/Subscription/components/ResumeSubscriptionModal'
import { instrumentResumeSubscriptionConfirm } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentPauseResumeSubscriptionEvents'
import { format, isAfter } from 'date-fns'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

interface Props {
  subscription: Subscription
}

export const SubscriptionSummary: VFC<Props> = ({ subscription }) => {
  const router = useRouter()
  const pageSource = usePageSource()

  const [modal, setModal] = useState<'activated' | 'resume' | 'resumed'>(
    undefined
  )

  const handleResumeSubscription = () => {
    updateSubscription(
      { pausedUntil: null },
      { onSuccess: () => setModal('resumed') }
    )

    triggerHotJarEvent('resume subscription confirmed')
    instrumentResumeSubscriptionConfirm()
  }

  const handleRestartSubscription = () => {
    updateSubscription(
      { cancelledAt: null },
      { onSuccess: () => setModal('activated') }
    )

    triggerHotJarEvent('restart subscription confirmed')
    trackAnalyticsEvent({
      name: 'subscription reactivate confirmed',
      data: {
        'page source': pageSource,
      },
    })
  }

  const { mutate: updateSubscription } = useUpdateSubscription()

  if (!subscription) return null

  const { cancelledAt, pausedUntil } = subscription
  const isPaused = isAfter(pausedUntil, new Date())

  const handlePreferencesClick = () => {
    router.push(routesConfig.subscription.pathname)
  }

  const handleOrdersClick = () => {
    router.push(routesConfig.upcoming.pathname)
    setModal(undefined)
  }

  return (
    <Box>
      <Flex direction={{ base: 'column', md: isPaused ? 'column' : 'row' }}>
        <Flex direction="row" color="aubergine" wrap="wrap">
          <SubscriptionIcons subscription={subscription} />
        </Flex>

        {isPaused && (
          <Text width="100%" variant="body-sm" my={8} color="aubergine">
            Your recipe subscription is paused until{' '}
            {format(pausedUntil, 'ddd DD MMM')}
          </Text>
        )}

        <Flex>
          <NextLink href={routesConfig.subscription.pathname} passHref>
            <Link
              href={routesConfig.subscription.pathname}
              variant={['body-xs', 'body-sm']}
              display="block"
              mr={12}
              onClick={() => {
                trackAnalyticsEvent({
                  name: 'edit preferences clicked',
                })
                triggerHotJarEvent('edit preferences clicked')
              }}
            >
              Edit subscription
            </Link>
          </NextLink>

          {isPaused && (
            <ButtonLink
              variant={['body-xs', 'body-sm']}
              display="block"
              mr={12}
              onClick={() => setModal('resume')}
            >
              Resume subscription now
            </ButtonLink>
          )}
        </Flex>
      </Flex>

      {cancelledAt && (
        <Box mt={8}>
          <SmallNotification
            buttonText="Activate subscription"
            onClick={handleRestartSubscription}
            message="Restart today to save time and stay healthy."
            title="Your subscription is inactive"
          />
        </Box>
      )}

      {modal === 'resume' && (
        <ResumeSubscriptionModal
          subscription={subscription}
          onResumeClick={handleResumeSubscription}
          onClose={() => setModal(undefined)}
        />
      )}

      {(modal === 'activated' || modal === 'resumed') && (
        <RestartSubscriptionSuccessModal
          source="orders"
          type={modal}
          onClose={() => setModal(undefined)}
          onOrdersClick={handleOrdersClick}
          onPreferencesClick={handlePreferencesClick}
          subscription={subscription}
        />
      )}
    </Box>
  )
}

const SmallNotification: VFC<{
  title: string
  message: string
  buttonText: string
  onClick: () => void
}> = ({ buttonText, message, onClick, title }) => (
  <Box
    borderWidth={1}
    backgroundColor="lemon-100"
    borderColor="pumpkin-400"
    color="aubergine"
    p={8}
    display="inline-block"
  >
    <Text variant={['body-xs', 'body-sm']}>
      <Text as="span" variant={['body-xs-500', 'body-sm-500']} mt={6} mb={10}>
        {title}:{' '}
      </Text>
      <Text as="span">{message} </Text>
      <ButtonLink
        display="inline"
        variant={['body-xs', 'body-sm']}
        onClick={onClick}
      >
        {buttonText}
      </ButtonLink>
    </Text>
  </Box>
)
