import {
  Box,
  TertiaryButton,
  Container,
  Flex,
  Text,
} from '@mindfulchefuk/design-system'
import React, { VoidFunctionComponent } from 'react'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { useRouter } from 'next/router'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'
import { useIsBBCGoodFood } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'

/** Note that this Notification does not use the BannerNotification or Notification components because it uses vastly different patterns */
export const NotificationPromoVoucherExperiment: VoidFunctionComponent = () => {
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )
  const discountConfig = getDiscountConfig(savedVoucherCode)

  const router = useRouter()

  const isBBCGoodFood = useIsBBCGoodFood()

  if (!discountConfig || isAuthenticated()) return null

  return (
    <Box
      position="sticky"
      top={-1}
      left={0}
      right={0}
      bg={isBBCGoodFood ? 'bbc-good-food-pink' : 'broccoli'}
      textAlign={['left', 'center']}
      boxShadow="standard"
      z="notification"
      color="white"
      data-testid="voucher-banner"
    >
      <Container py={14} px={20}>
        <Flex justify="center" align="center">
          <Text as="h3" variant="body-sm-500-caps" pr={[8, 16]}>
            {discountConfig.totalDiscountSentence}
          </Text>
          <TertiaryButton
            data-testid="voucher-banner-claim"
            as="a"
            size="x-small"
            variant="secondary"
            onClick={() => {
              logEvent({
                category: ANALYTICS_CATEGORIES.homepage,
                action: 'Notification Bar CTA clicked',
                label: 'Voucher CTA',
              })

              router.push(routesConfig.chooseRecipes.pathname)
            }}
          >
            Try now
          </TertiaryButton>
        </Flex>
      </Container>
    </Box>
  )
}
