import coreDiscountsConfig, {
  DiscountConfig,
  GENERIC_VOUCHER_CONFIG,
} from '@mindfulchefuk/config/coreDiscountsConfig'

export function getDiscountConfig(voucherCode: string): DiscountConfig {
  if (!voucherCode) return undefined

  const result: DiscountConfig = coreDiscountsConfig.find((configItem) => {
    const code: string[] = Array.isArray(configItem.code)
      ? configItem.code
      : [configItem.code]

    // Dynamically create regexp to match voucherCode - e.g. /^(one|two|three|four).*$/
    const tester = new RegExp(`^.*(${code.join('|')}).*$`, 'i')

    return tester.test(voucherCode)
  })

  return result ?? GENERIC_VOUCHER_CONFIG
}
