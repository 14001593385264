export const homepageVariantFallback = {
  id: 1,
  title: 'Default',
  heroTitle:
    '94% of customers say Mindful Chef <br /> has made healthy eating easier for them',
  heroByline: "Discover the UK's #1 rated recipe box",
  path: '/',
  weeklyRecipesSubheading: 'Choose from 20 delicious recipes each week.',
  heroQuote: 'Fully flexible subscription,<br /> skip or pause at anytime',
  emphasisInHeroQuote: 'Fully flexible',
  trustPilotCarouselTitle:
    'Over 30 million meals delivered to more than 400,000 happy customers',
  plates: {
    id: 1,
    onePersonUrl: '/choose-recipes?initialPortionCount=1',
    twoPersonUrl: '/choose-recipes?initialPortionCount=2',
    fourPersonUrl: '/choose-recipes?initialPortionCount=4',
    calloutText: 'Award-winning ingredients, delivered to your door',
    twoPersonImage: {
      path: 'defaults/plates/1pp.png',
      altText: 'Miso prawn noodle bowl',
      width: 1080,
    },
    onePersonImage: {
      path: 'defaults/plates/2pp.png',
      altText: 'Chicken with roasted carrots',
      width: 1080,
    },
    fourPersonImage: {
      path: 'defaults/plates/4pp.png',
      altText: '4 person family',
      width: 1080,
    },
  },
  oneFeedsTwoText:
    "We've donated over 19 million meals to children living in poverty through One Feeds Two.",
  oneFeedsTwoEmphasisText: 'donated over 19 million meals',
  howItWorks: {
    title: 'How it works',
    items: [
      {
        id: 1,
        title: 'Choose your recipes',
        description:
          'Load your box with up to 5 tasty recipes a week (or as often as you like)',
        image: {
          altText: 'How it works - step 1',
          path: 'defaults/howItWorks/how-it-works-step-1.png',
          width: 1000,
        },
      },
      {
        id: 2,
        title: 'Schedule a delivery',
        description:
          'Mindful Chef gets your pre-portioned, ethically sourced ingredients ready',
        image: {
          altText: 'How it works - step 2',
          path: 'defaults/howItWorks/how-it-works-step-2.png',
          width: 1000,
        },
      },
      {
        id: 3,
        title: 'Enjoy feel-good food',
        description:
          'Easy to follow recipes on the table in as little as 15 mins',
        image: {
          altText: 'How it works - step 3',
          path: 'defaults/howItWorks/how-it-works-step-3.png',
          width: 1000,
        },
      },
    ],
  },
  standout: {
    title: 'What makes Mindful Chef stand out?',
    subtitle:
      'Packed with fresh, high-quality ingredients sourced directly from local farmers and producers, each box is designed to bring out the best in seasonal produce and inspire your culinary creativity.',
    items: [
      {
        id: 1,
        title: 'The quality of the food (really) matters',
        description:
          'Only the best in local British produce. 100% British Meat, sustainably sourced fish, and award winning veg in your box.',
        image: {
          altText: 'Pasta and chicken dish',
          path: 'defaults/standout/mc-qualities-1.png',
          width: 1000,
        },
      },
      {
        id: 2,
        title: '94% say Mindful Chef has made healthy eating easier',
        description:
          "Meals designed for balance, not elimination. It's simple: good quality food in the right portions. No fad diets here.",
        image: {
          altText: 'Myles & Giles',
          path: 'defaults/standout/mc-qualities-2.png',
          width: 1000,
        },
      },
      {
        id: 3,
        title: "Mindful Chef's mission is a force for good",
        description:
          'Through reducing their impact on the environment, tackling food waste and donating to children living in poverty.',
        image: {
          altText: 'Certified B-corp logo',
          path: 'defaults/standout/mc-qualities-3.png',
          width: 1000,
        },
      },
    ],
  },
  chooseRecipesQueryParams: '',
}
