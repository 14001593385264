import React, { ReactElement } from 'react'

import { Box } from '@mindfulchefuk/design-system/layout'
import { useIsBBCGoodFood } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'
import { NavCTA } from '@mindfulchefuk/features/Navigation/components/Header/shared/NavCTA'

export const NavSignUpCTA = ({
  onClick,
}: {
  onClick?: () => void
}): ReactElement => {
  const isBBCGoodFood = useIsBBCGoodFood()
  const { chooseRecipesQueryParams } = useCMSHomepageVariant()

  return (
    <NavCTA
      variant={isBBCGoodFood ? 'bbc-good-food' : 'primary'}
      testId="nav-cta-sign-up"
      href={routesConfig.chooseRecipes.pathname + chooseRecipesQueryParams}
      onClick={onClick}
    >
      <Box as="span" display={{ base: 'inline', sm: 'none' }}>
        Sign up
      </Box>

      <Box as="span" display={{ base: 'none', sm: 'inline' }}>
        Choose recipes
      </Box>
    </NavCTA>
  )
}
