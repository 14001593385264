import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { addNotification } from '@mindfulchefuk/features/Notifications/notificationsSlice'
import { getServerVersion } from '@mindfulchefuk/services/axios/getServerVersion'
import { getVersion } from '@mindfulchefuk/utils/getVersion'

const pollingFrequency =
  parseInt(process.env.NEXT_PUBLIC_VERSION_CHECK_POLLING_FREQUENCY, 10) ||
  1000 * 60 * 60 * 24 * 3 // milliseconds * seconds * minutes * hours * days

const useVersionCheck = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setInterval(async () => {
      const serverVersion = await getServerVersion()
      const clientVersion = getVersion()

      const isMismatch = serverVersion !== clientVersion

      if (isMismatch) {
        window.clearInterval(timer)
        dispatch(addNotification({ id: 'warning-version-update' }))
      }
    }, pollingFrequency)

    return () => clearInterval(timer)
  }, [dispatch])
}

export default useVersionCheck
