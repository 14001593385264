import React from 'react'
import { Box, Text, Anchor, Image } from '@mindfulchefuk/design-system'

export interface BannerProps {
  count: number
}

export const OneFeedsTwoCountBanner = ({ count }: BannerProps) => {
  if (!count || count < 1) return null
  return (
    <Anchor href="https://www.mindfulchef.com/one-feeds-two" target="_blank">
      <Box position="relative" backgroundColor="pumpkin-600">
        <Image resolve src="oneFeedsTwoCountBackground.png" />
        <Box
          textAlign="center"
          px={{ base: 32, md: 12 }}
          py={{ base: '18px', md: 8 }}
          color="white"
          position="absolute"
          top={0}
          left={0}
        >
          <Text as="p" variant={{ base: 'heading-xs', md: 'heading-xxs' }}>
            You&apos;ve given
          </Text>
          <Text as="p" variant={{ base: 'heading-lg', md: 'heading-sm' }}>
            {new Intl.NumberFormat().format(count)}
          </Text>
          <Text
            as="p"
            mb={10}
            variant={{ base: 'body-sm-500', md: 'body-xs-500' }}
          >
            school {count === 1 ? 'meal' : 'meals'} to a child living in poverty
          </Text>
          <Text as="p" variant={{ base: 'heading-xs', md: 'heading-xxs' }}>
            Thank you
          </Text>
        </Box>
      </Box>
    </Anchor>
  )
}
