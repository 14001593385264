import React from 'react'

import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { Box } from '@mindfulchefuk/design-system'

export const MushroomsIllustration = () => (
  <Box
    height="85px"
    width="200px"
    position="relative"
    alignSelf={['flex-end', 'flex-start']}
  >
    <Illustration
      type="cross-section-mushroom-1"
      transform="rotate(131.37deg)"
      left="0px"
      top="22px"
    />
    <Illustration
      type="cross-section-mushroom-1"
      transform="rotate(38.64deg)"
      left="80px"
      top="2px"
    />
    <Illustration type="cross-section-mushroom-1" left="150px" top="42px" />
  </Box>
)
