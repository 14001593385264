import React from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { RootState } from '@mindfulchefuk/types/store'
import { DATE_FORMAT } from '@mindfulchefuk/constants'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'

export const NotificationSuccessDeliverySkipped = () => {
  const id = 'success-delivery-skipped'

  const date = useSelector(
    (store: RootState) => store.notifications.meta[id].date
  )
  const formattedDate = format(date, DATE_FORMAT)

  return (
    <BannerNotification
      variant="success"
      title="Delivery skipped"
      id={id}
      isDismissable
      shouldAutoDismiss
    >
      Thank you for your feedback. Your delivery for {formattedDate} has been
      skipped.
    </BannerNotification>
  )
}
