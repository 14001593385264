import { datadogRum } from '@datadog/browser-rum'
import { selectEmail } from '@mindfulchefuk/features/Customer/selectors'
import { useGetCustomer } from '@mindfulchefuk/features/Customer'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { RootState } from '@mindfulchefuk/types/store'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useDatadogRumUser = () => {
  const id = getCustomerId()

  const checkoutEmail = useSelector((state: RootState) => state.checkout.email)

  const { data: customerEmail } = useGetCustomer({ select: selectEmail })

  const email = customerEmail || checkoutEmail

  useEffect(() => {
    if (email) {
      datadogRum.setUser({ email, id })
    } else {
      datadogRum.removeUser()
    }
  }, [email, id])
}
