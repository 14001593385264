import {
  analyticsEvents,
  DashBoardTabs,
  DeliveryStatus,
  DeliveryType,
  OrderTypes,
  PageSource,
  ProductType,
  Section,
} from '@mindfulchefuk/analytics'
import type { TCustomerRecipeFilter } from '@mindfulchefuk/features/CustomerRecipes/interfaces'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentPastOrdersViewed = ({
  numberOfDeliveries,
}: {
  numberOfDeliveries: number
}) => {
  const { name, data } = analyticsEvents.pastOrdersViewed({
    numberOfDeliveries,
  })

  instrument(name, { ...data })
}

export const instrumentPageNavClicked = ({ tab }: { tab: DashBoardTabs }) => {
  const { name, data } = analyticsEvents.pageNavClicked({
    tab,
  })

  instrument(name, { ...data })
}
export const instrumentDeliveryCardExpanded = ({
  upcomingDeliveryWeek,
  deliveryStatus,
  updatedByTheUser = 'yes',
  productTypesInBasket,
  deliveryType,
}: {
  upcomingDeliveryWeek: number
  deliveryStatus: DeliveryStatus
  updatedByTheUser: 'yes' | 'no'
  productTypesInBasket: 'both' | 'recipe' | 'ready to go'
  deliveryType: DeliveryType
}) => {
  const { name, data } = analyticsEvents.deliveryCardExpanded({
    upcomingDeliveryWeek,
    deliveryStatus,
    updatedByTheUser,
    productTypesInBasket,
    deliveryType,
  })

  instrument(name, { ...data })
}

export const instrumentDeliveryCardCollapsed = () => {
  const { name, data } = analyticsEvents.deliveryCardCollapsed()

  instrument(name, { ...data })
}

export const instrumentProductDetailsClicked = ({
  pageSource,
  upcomingDeliveryWeek,
  productCategory,
  productType,
  productId,
  productName,
}: {
  pageSource: PageSource
  upcomingDeliveryWeek?: number
  productCategory: ProductType
  productType: 'recipe' | 'ready to go'
  productId: string
  productName: string
}) => {
  instrument('product details clicked', {
    'page source': pageSource,
    'upcoming delivery week': upcomingDeliveryWeek,
    'product category': productCategory,
    'product type': productType,
    'product id': productId,
    'product name': productName,
  })
}

export const instrumentOrderDetailsClicked = ({
  deliveryStatus,
  productTypesInBasket,
  pageSource,
  promotionalDelivery,
  updatedByTheUser,
  upcomingDeliveryWeek,
}: {
  deliveryStatus: DeliveryStatus
  productTypesInBasket: OrderTypes
  pageSource: PageSource
  promotionalDelivery: 'yes' | 'no'
  updatedByTheUser: 'yes' | 'no'
  upcomingDeliveryWeek: number
}) => {
  const { name, data } = analyticsEvents.orderDetailsClicked({
    deliveryStatus,
    productTypesInBasket,
    pageSource,
    promotionalDelivery,
    updatedByTheUser,
    upcomingDeliveryWeek,
  })

  instrument(name, { ...data })
}

export const instrumentPlaceAnOrderClicked = ({
  isUpsell,
  pageSource,
  productCategory,
  productType,
  section = 'none',
}: {
  isUpsell: 'true' | 'false'
  pageSource: PageSource
  productCategory: ProductType
  productType: 'recipe' | 'ready to go'
  section?: Section
}) => {
  const { name, data } = analyticsEvents.placeAnOrderClicked({
    isUpsell,
    pageSource,
    productCategory,
    productType,
    section,
  })

  instrument(name, { ...data })
}

export const instrumentOrderOneOffBoxClicked = () => {
  const { name } = analyticsEvents.orderOneOffBoxClicked()

  instrument(name)
}

export const instrumentYourRecipesViewed = (params: {
  pageParam: number
  search: string
  filter: TCustomerRecipeFilter
  isEmpty: boolean
}) => {
  const { name, data } = analyticsEvents.yourRecipesViewed(params)
  instrument(name, data)
}

export const instrumentSearchRecipesClicked = (pageSource: PageSource) => {
  const { name, data } = analyticsEvents.searchRecipesClicked(pageSource)
  instrument(name, data)
}

export const instrumentReferAFriendButtonClicked = (pageSource: PageSource) => {
  const { name, data } = analyticsEvents.referAFriendButtonClicked(pageSource)
  instrument(name, data)
}
