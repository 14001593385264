import React, { useState, VFC } from 'react'
import { Box } from '@mindfulchefuk/design-system/layout/Box'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { ResetButton } from '@mindfulchefuk/design-system/Button'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'

import {
  instrumentNavClicked,
  instrumentNavExpanded,
} from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentNavigation'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'
import { useAvailableDates } from '@mindfulchefuk/features/Operations/hooks/useAvailableDates'
import { SignedInNavMobile } from './SignedInNavMobile'
import { SignedInNavDesktop } from './SignedInNavDesktop'

interface Props {
  variant: 'light' | 'dark'
}

export const SignedInNav: VFC<Props> = ({ variant }) => {
  const [isHamburgerOpen, setIsHambugerOpen] = useState(false)
  const { isNewCustomer, isFetched } = useIsNewCustomer()

  const pageSource = usePageSource()

  const textColor = variant === 'light' ? 'aubergine' : 'salt'

  const {
    recipes: { firstAvailableDeliveryDate },
  } = useAvailableDates()

  const handleNavExpand = (label: string) => {
    instrumentNavExpanded({
      link: label,
      section: 'top nav',
      pageSource,
    })
  }

  const handleNavClick = (label: string) => {
    instrumentNavClicked({
      link: label,
      section: 'top nav',
      pageSource,
    })
  }

  return (
    <>
      <Box order={-1} display={{ md: 'none' }} pr={16}>
        <ResetButton
          onClick={() => {
            setIsHambugerOpen(true)
            handleNavExpand('hamburger-button')
          }}
          data-testid="hamburger-button"
        >
          <Icon color={textColor} type="menu" size={32} />
        </ResetButton>
      </Box>
      <SignedInNavMobile
        isNewCustomer={isNewCustomer}
        isOpen={isHamburgerOpen}
        firstAvailableDate={firstAvailableDeliveryDate}
        onNavClick={handleNavClick}
        onNavExpand={handleNavExpand}
        pageSource={pageSource}
        setIsOpen={setIsHambugerOpen}
      />
      {isFetched && (
        <SignedInNavDesktop
          isNewCustomer={isNewCustomer}
          firstAvailableDate={firstAvailableDeliveryDate}
          onNavClick={handleNavClick}
          onNavExpand={handleNavExpand}
          pageSource={pageSource}
        />
      )}
    </>
  )
}
