import React, { FunctionComponent } from 'react'

import {
  Box,
  Flex,
  Icon,
  ResetButton,
  Text,
} from '@mindfulchefuk/design-system'
import { IconType } from '@mindfulchefuk/design-system/Icon/svgs'
import { RecipeFilterType } from '@mindfulchefuk/features/Recipes/interfaces'

interface Props {
  label: string
  icon: IconType
  id: RecipeFilterType
  onToggle?: () => void
  isOpen: boolean
}
export const RecipeFilter: FunctionComponent<Props> = ({
  id,
  label,
  icon,
  isOpen,
  onToggle,
  children,
}) => {
  if (!onToggle) {
    return (
      <Box position="relative">
        <Flex color="aubergine">
          {icon && <Icon type={icon} flexShrink={0} mr={8} />}
          <Text
            variant="body-sm-500-caps"
            as="span"
            color="aubergine"
            data-testid={`${id}-label`}
          >
            {label}
          </Text>
        </Flex>
        {children}
      </Box>
    )
  }

  return (
    <Box position="relative">
      <ResetButton
        display="block"
        data-testid={`recipe-filter-${id}`}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation()
          onToggle()
        }}
      >
        <Flex color="aubergine">
          {icon && <Icon type={icon} flexShrink={0} mr={8} />}
          <Text
            variant="body-sm-500-caps"
            as="span"
            color="aubergine"
            data-testid={`${id}-label`}
          >
            {label}
          </Text>
          <Icon ml={10} type={isOpen ? 'chevron-up' : 'chevron-down'} />
        </Flex>
      </ResetButton>
      {children}
    </Box>
  )
}
