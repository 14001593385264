import { useGetDeliveries } from '@mindfulchefuk/features/Delivery/hooks/useGetDeliveries'
import { ShopOrderStatus } from '@mindfulchefuk/query'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { useGetCardDetails } from '@mindfulchefuk/hooks/useGetCardDetails'
import { useGetShopOrders } from '@mindfulchefuk/features/ShopOrder/hooks/useGetShopOrders'
import { useSignedInOnboardingFlow } from '@mindfulchefuk/hooks/useSignedInOnboardingFlow'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'

export const useIsNewCustomer = () => {
  const customerId = getCustomerId()
  const isFeatureEnabled = useSignedInOnboardingFlow()
  const isCustomer = !!customerId
  const isEnabled = isCustomer && isFeatureEnabled

  const options = {
    enabled: isEnabled,
    staleTime: STALE_1_HOUR, // We don't want these to be called all the time on every page
  }

  const { isFetched: cardDetailsAreFetched, data: cardDetails } =
    useGetCardDetails(customerId, options)

  const { data: shopOrders = [], isFetched: shopOrdersAreFetched } =
    useGetShopOrders(options)

  const { data: deliveries = [], isFetched: deliveriesAreFetched } =
    useGetDeliveries(options)

  const ordersSubmitted = shopOrders.filter(
    (order) => order.status !== ShopOrderStatus.BASKET
  )

  const isFetched =
    cardDetailsAreFetched && shopOrdersAreFetched && deliveriesAreFetched

  const hasNoCardDetails = isFetched && !cardDetails

  const isNewCustomer =
    isFetched && !cardDetails && !deliveries.length && !ordersSubmitted.length

  return {
    hasNoCardDetails,
    isEnabled,
    isFeatureEnabled,
    isFetched,
    isCustomer,
    isNewCustomer,
  }
}
