import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { RootState } from '@mindfulchefuk/types/store'
import React from 'react'
import { useSelector } from 'react-redux'

export const NotificationSuccessFeedbackShared = () => {
  const id = 'success-feedback-shared'
  const isPlural = useSelector(
    (store: RootState) => store.notifications.meta[id].isPlural
  )

  return (
    <BannerNotification
      variant="success"
      title={`Rating${isPlural ? 's' : ''} submitted`}
      id={id}
      isDismissable
      shouldAutoDismiss={7}
    >
      Thank you for sharing your feedback
    </BannerNotification>
  )
}
