import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { RootState } from '@mindfulchefuk/types/store'
import React from 'react'
import { useSelector } from 'react-redux'

const id = 'success-account-preferences-changed'
export const NotificationSuccessAccountPreferencesChanged = () => {
  const hasActiveSubscription = useSelector(
    (store: RootState) => store.notifications.meta[id].hasActiveSubscription
  )

  return (
    <BannerNotification
      variant="success"
      title="Preferences changed"
      id={id}
      isDismissable
      shouldAutoDismiss
    >
      {hasActiveSubscription
        ? 'You can view and edit the recipes for your updated deliveries from the calendar.'
        : "We didn't schedule any new deliveries as your subscription is inactive"}
    </BannerNotification>
  )
}
