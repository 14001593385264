import axios from 'axios'
import get from 'lodash/get'

const endpoint = '/api/version'
const DEFAULT_VERSION = '0.0.0'

export const getServerVersion = async (): Promise<string> => {
  const response = await axios.get(endpoint, {
    headers: {
      Accept: 'application/json',
    },
  })

  /**
   * When maintenance mode is on the server will respond with
   * the maintenance page to any requests that are made.
   *
   * This response will have a content-type of 'text/html' and a
   * status of 200 but won't include the desired json object.
   *
   * Because the user may have the app open; we still want
   * them to be notified so we MUST return a value here.
   */
  return get(response, `data.version`, DEFAULT_VERSION)
}
