import { QueryOptions, useQuery } from '@tanstack/react-query'

import {
  CustomerDeliveryStats,
  getCustomerDeliveryStats,
} from '@mindfulchefuk/features/Delivery/services/getCustomerDeliveryStats'
import { STALE_10_MINS } from '@mindfulchefuk/constants'

export const useGetCustomerDeliveryStats = (
  options: QueryOptions<CustomerDeliveryStats> = {}
) =>
  useQuery({
    queryKey: ['customer-delivery-stats'],
    queryFn: () => getCustomerDeliveryStats(),
    staleTime: STALE_10_MINS,
    ...options,
  })
